
import {useContext, useEffect} from 'react'

import {useTranslation} from 'react-i18next'

import {getAccountUrl} from 'helpers/url.js'

import PageContext from 'contexts/page-context.js'

function AccountLayout({children}) {
  const {t} = useTranslation('translation', {keyPrefix: 'UserTabsNavigation'})

  const {setHeaderNavItems} = useContext(PageContext)

  useEffect(() => {
    setHeaderNavItems([
      {title: t('pageTitle'), path: getAccountUrl()}
    ])
  }, [setHeaderNavItems, t])

  return children
}

export default AccountLayout
