import React, {useMemo} from 'react'

import {Dropdown, Empty, Spin} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {createGlobalStyle} from 'styled-components'

import {isWithinThreshold} from 'util/date.js'

import ActiveWorkspacesCounter from 'components/display/workspaces/active-workspaces-counter.js'
import WorkspaceOverview from 'components/display/workspaces/workspace-overview.js'

const {SHUTTING_DOWN_THRESHOLD} = process.env

// Only solution to avoid the antd dropdown menu item hover effect…
const GlobalStyle = createGlobalStyle`
  .ant-dropdown.custom-dropdown-menu {
    max-height: 500px;
    overflow-y: auto;
  }

  .ant-dropdown.custom-dropdown-menu .ant-dropdown-menu-item:hover,
  .ant-dropdown.custom-dropdown-menu .ant-dropdown-menu-item-active {
    background-color: transparent;
  }
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: ${({theme}) => theme.antd.paddingXXS}px;
  }
`

function WorkspacesDropdown({workspaces, isLoading, updateWokspaces, children}) {
  const {t} = useTranslation('translation')

  const items = useMemo(() => {
    if (isLoading) {
      return [{label: <Spin/>}]
    }

    if (workspaces.length === 0) {
      return [{label: <Empty description={t('Project.WorkspacesDropdown.noWorkspace')}/>}]
    }

    return [
      {
        type: 'group',
        label: (
          <ActiveWorkspacesCounter activeWorkspaceCount={workspaces.length}/>
        )
      },
      ...workspaces.map(workspace => ({
        label: (
          <WorkspaceOverview
            workspace={workspace}
            owner={workspace.project.owner}
            members={workspace.users}
            isShuttingDownSoon={isWithinThreshold(workspace.keepActiveUntil, SHUTTING_DOWN_THRESHOLD)}
          />
        )
      }))
    ]
  }, [workspaces, isLoading, t])

  return (
    <>
      <GlobalStyle/>
      <Dropdown
        destroyPopupOnHide
        trigger={['click']}
        menu={{items, selectable: false}}
        overlayStyle={{minWidth: 'auto'}}
        overlayClassName='custom-dropdown-menu'
        placement='bottomRight'
        onOpenChange={updateWokspaces}
      >
        {/* Quick fix : Add div to prevent Antd bug with StrictMode : https://github.com/ant-design/ant-design/issues/40340#issuecomment-1851424186 */}
        <div style={{cursor: 'pointer'}}>
          {children}
        </div>
      </Dropdown>
    </>
  )
}

WorkspacesDropdown.propTypes = {
  workspaces: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  updateWokspaces: PropTypes.func,
  children: PropTypes.node
}

export default WorkspacesDropdown
