/* eslint-disable react/prop-types */

import Icon, {CalendarOutlined, SyncOutlined, UserOutlined} from '@ant-design/icons'
import {Flex, Typography} from 'antd'
import {useTranslation} from 'react-i18next'

import api from 'services/api/index.js'

import {formatDate, getRelativeTimeFormat} from 'util/date.js'

import FullNameAvatar from 'components/display/avatar/full-name-avatar.js'
import UserAvatar from 'components/display/avatar/user-avatar.js'
import SidebarSection from 'components/layouts/sidebar-section.js'

const {Text} = Typography

const styles = {
  empty: {
    width: 14
  }
}

function MetaDataItem({icon, title, subtitle}) {
  return (
    <Flex vertical>
      <Flex gap='small' align='center'>
        <Icon component={icon}/>
        <Text strong>{title}</Text>
      </Flex>
      <Flex gap='small' align='center'>
        <div style={styles.empty}/>
        <Text type='secondary'>{subtitle}</Text>
      </Flex>
    </Flex>
  )
}

function WorkflowMetadataSection({createdAt, updatedAt, operationsUpdatedAt, operationsUpdatedBy}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.WorkflowEditor.WorkflowMetadataSection'})

  return (
    <SidebarSection title={t('title')}>
      <MetaDataItem
        icon={CalendarOutlined}
        title={t('createdAt')}
        subtitle={formatDate(createdAt, 'long')}
      />

      <MetaDataItem
        icon={SyncOutlined}
        title={t('updatedAt')}
        subtitle={getRelativeTimeFormat(updatedAt)}
      />

      {operationsUpdatedAt && (
        <MetaDataItem
          icon={CalendarOutlined}
          title={t('operationsUpdatedAt')}
          subtitle={getRelativeTimeFormat(operationsUpdatedAt)}
        />
      )}

      {operationsUpdatedBy && (
        <MetaDataItem
          icon={UserOutlined}
          title={t('operationsUpdatedBy')}
          subtitle={(
            <FullNameAvatar fullName={operationsUpdatedBy.fullName}>
              <UserAvatar
                avatar={api.getAvatarURL(operationsUpdatedBy, 'small')}
                size='small'
              />
            </FullNameAvatar>
          )}
        />
      )}
    </SidebarSection>

  )
}

export default WorkflowMetadataSection
