/* eslint-disable react/prop-types */

import {GoldOutlined, FileOutlined, FolderOutlined} from '@ant-design/icons'
import {Flex, Tag, Typography} from 'antd'
import {useTranslation} from 'react-i18next'

import FileSize from 'components/ui/file-size.js'

const {Text} = Typography

const styles = {
  tag: {margin: 0}
}

export function countFoldersAndFiles(array, filterFunction, isFolderCounted = false) {
  let folderCount = 0
  let fileCount = 0
  let totalSize = 0

  function recurse(files) {
    for (const file of files) {
      if (file.children) {
        if (isFolderCounted && (!filterFunction || filterFunction(file))) {
          folderCount++
        }

        recurse(file.children)
      } else if (file.isLeaf && (!filterFunction || filterFunction(file))) {
        fileCount++
        totalSize += file.size || 0
      }
    }
  }

  recurse(array)
  return {folderCount, fileCount, totalSize}
}

export function Counter({icon, children}) {
  return (
    <Tag
      color='default'
      icon={icon}
      bordered={false}
      style={styles.tag}
    >
      <Text>{children}</Text>
    </Tag>
  )
}

function Counters({folderCount, fileCount, totalSize, justify = 'start'}) {
  const {t} = useTranslation('translation', {keyPrefix: 'FilesetExplorer.Counters'})

  return (
    <Flex wrap='wrap' align='baseline' justify={justify} gap='small'>
      {folderCount > 0 && (
        <Counter icon={<FolderOutlined/>}>
          <Text>{folderCount} {t('folders', {count: folderCount})}</Text>
        </Counter>
      )}

      {fileCount > 0 && (
        <Counter icon={<FileOutlined/>}>
          <Text>{fileCount} {t('files', {count: fileCount})}</Text>
        </Counter>
      )}

      {totalSize > 0 && (
        <Counter icon={<GoldOutlined/>}>
          <FileSize bytes={totalSize}/>
        </Counter>
      )}
    </Flex>
  )
}

export default Counters
