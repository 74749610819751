/* eslint-disable react/prop-types */

import {useContext} from 'react'

import {CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import {Flex, Checkbox as CheckboxAntd, theme} from 'antd'

import OperationParamsContext from 'contexts/operation-params-context.js'

import withEditable from 'hoc/with-editable.js'

function Display({label, name}) {
  const {params} = useContext(OperationParamsContext)
  const {token} = theme.useToken()

  return (
    <Flex wrap='wrap' gap='small'>
      <div>{label}</div>
      <div>{params[name] ? (
        <CheckCircleOutlined style={{color: token.colorSuccess}}/>
      ) : (
        <CloseCircleOutlined style={{color: token.colorError}}/>
      )}
      </div>
    </Flex>
  )
}

function Field({label, name}) {
  const {params, initialParams, updateParam} = useContext(OperationParamsContext)

  const handleChange = event => {
    const {checked} = event.target
    updateParam({name, value: checked})
  }

  return (
    <Flex align='start'>
      <CheckboxAntd
        name={name}
        checked={params ? params[name] : initialParams[name]}
        onChange={handleChange}
      >
        {label}
      </CheckboxAntd>
    </Flex>
  )
}

export default withEditable(Field, Display, {label: null})
