import {PlusCircleOutlined} from '@ant-design/icons'
import {Button, Flex, Space, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {OPERATIONS} from 'util/operations.js'

import OperationIcon from 'components/display/operation-icon.js'

const {Text} = Typography

const OperationSelectItemStyled = styled(Flex)`
    padding: ${({theme}) => theme.antd.paddingXS}px;
    border-radius: ${({theme}) => theme.antd.borderRadius}px;
    cursor: pointer;

    &:hover {
        background-color: ${({theme}) => theme.antd.contentBgLight};
    }
`

function OperationSelectItem({type, onClick}) {
  const {t} = useTranslation('operations', {keyPrefix: type.replace(':', '-')})
  const {icon, type: operationType} = OPERATIONS[type]

  return (
    <OperationSelectItemStyled
      justify='space-between'
      align='center'
      onClick={() => onClick(type)}
    >
      <Space>
        <OperationIcon icon={icon} type={operationType}/>
        <Flex vertical>
          {t('label')}
          <Text type='secondary'>{type}</Text>
        </Flex>
      </Space>

      <Button
        type='text'
        icon={<PlusCircleOutlined/>}
      />
    </OperationSelectItemStyled>
  )
}

OperationSelectItem.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default OperationSelectItem
