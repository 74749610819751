import {useCallback, useContext, useEffect, useState} from 'react'

import {Flex} from 'antd'
import {orderBy} from 'lodash-es'
import PropTypes from 'prop-types'

import api from 'services/api/index.js'

import ProjectContext from 'contexts/project.js'
import WorkflowContext from 'contexts/workflow-context.js'

import useEventSource from 'hooks/use-event-source.js'

import WorkspaceModal from 'components/display/workflows/workspace-modal.js'

import WorkspaceSelector from 'containers/workspaces-manager/workspace-selector.js'

import WorkspaceControls from './workspaces-manager/workspace-controls.js'

function WorkspaceManager({hasControls}) {
  const {project} = useContext(ProjectContext)
  const {workflow, workspace, setCurrentWorkspaceId} = useContext(WorkflowContext)
  const [workspaces, setWorkspaces] = useState([])

  const eventSource = useEventSource({url: `/projects/${project._id}/events`, enabled: true})

  // Initialize active workspaces list on component mount
  useEffect(() => {
    const fetchActiveWorkspace = async () => {
      if (await api.isAuthenticated()) {
        const workspaces = await api.getWorkflowWorkspaces(workflow._id)
        const activeWorkspace = workspaces.filter(workspace => ['active', 'pending'].includes(workspace.status))
        setWorkspaces(activeWorkspace)
      }
    }

    fetchActiveWorkspace()
  }, [workflow._id])

  useEffect(() => {
    async function updateActiveWorkspace(message) {
      const {type, workspace, status} = message

      if (type === 'workspace:created' && workspace.workflow._id === workflow._id) { // Check if the workspace belongs to the current workflow
        setWorkspaces(workspaces => [workspace, ...workspaces])
      }

      if (type === 'workspace:status-updated' && workspaces.map(({_id}) => _id).includes(workspace)) { // Check if the workspace is already know of the current workflow
        if (['active', 'pending'].includes(status)) {
          setWorkspaces(workspaces => orderBy(workspaces.map(ws => ws._id === workspace ? {...ws, status} : ws)), ['createdAt'], ['desc'])
        } else {
          setWorkspaces(workspaces => workspaces.filter(ws => ws._id !== workspace))
        }
      }
    }

    if (eventSource) {
      eventSource.on('message', updateActiveWorkspace)
    }

    return () => {
      if (eventSource) {
        eventSource.off('message', updateActiveWorkspace)
      }
    }
  }, [eventSource, workspaces, workflow._id])

  const selectWorkspace = useCallback(({key}) => {
    setCurrentWorkspaceId(key)
  }, [setCurrentWorkspaceId])

  return (
    <>
      <Flex justify='center' gap='small'>
        {hasControls && <WorkspaceControls/>}

        <WorkspaceSelector
          workspaces={workspaces}
          workspace={workspace}
          handleSelectWorkspace={selectWorkspace}
        />
      </Flex>

      <WorkspaceModal/>
    </>
  )
}

WorkspaceManager.propTypes = {
  hasControls: PropTypes.bool
}

export default WorkspaceManager
