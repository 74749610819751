/* eslint-disable react/prop-types */

import {Flex, Tag, Typography} from 'antd'
import {nodeDimensions} from 'theme.js'

import OperationIcon from 'components/display/operation-icon.js'

const {Text} = Typography

const styles = {
  tag: {
    maxWidth: `${nodeDimensions.width - 20}px`,
    textOverflow: 'ellipsis',
    margin: 0
  }
}

function OperationInfos({type, icon, title, tags, subtitle}) {
  return (
    <>
      <Flex align='center' gap='small'>
        <OperationIcon icon={icon} type={type}/>
        <Flex vertical>
          <Text strong>{title}</Text>
          <Text type='secondary'>{subtitle}</Text>
        </Flex>
      </Flex>

      <Flex gap={4} wrap='wrap'>
        {tags.map(tag => (
          <Tag key={tag} style={styles.tag}><Text ellipsis>{tag}</Text></Tag>
        ))}
      </Flex>
    </>
  )
}

export default OperationInfos
