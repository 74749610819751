/* eslint-disable react/prop-types, no-unused-vars */

import {useContext, useEffect, useState} from 'react'

import {Button, Flex, Input, Alert} from 'antd'
import {isEqual} from 'lodash-es'
import {useTranslation} from 'react-i18next'

import {getValidationMessages} from 'util/joi-validations.js'
import {formatCode} from 'util/json.js'
import {getFailedChecks} from 'util/operation-checks.js'

import OperationParamsContext from 'contexts/operation-params-context.js'

import ValidationMessages from 'components/display/form/validation-messages.js'
import ParamsStep from 'components/display/operation-params/params-step.js'
import PreparationChecks from 'components/display/operation-params/preparation-checks.js'
import CodeBlock from 'components/ui/code-block.js'

function Params({params, inputContext, preparationContext, triggerPreparation}) {
  const {t} = useTranslation('common')

  const failedChecks = getFailedChecks(preparationContext)

  const {validationErrors, updateParams, isEditingEnable, setHasFormValuesChanged, hasFormValuesChanged} = useContext(OperationParamsContext)

  const [value, setValue] = useState(formatCode(params))
  const [validationMessages, setValidationMessages] = useState(getValidationMessages(validationErrors) || {})

  const validationMessagesKeys = Object.keys(validationMessages)

  const isSaveDisabled = validationMessagesKeys.length > 0

  useEffect(() => {
    setValidationMessages(validationErrors ? getValidationMessages(validationErrors) : {})
  }, [validationErrors])

  const handleChange = e => {
    setValidationMessages({})
    setValue(e.target.value)
  }

  const submit = async () => {
    try {
      const parsedValue = JSON.parse(value)
      await updateParams(parsedValue)
    } catch {
      setValidationMessages({invalid: ['Invalid JSON format']})
    }
  }

  useEffect(() => {
    try {
      const parsedValue = JSON.parse(value)
      setHasFormValuesChanged(isEqual(params, parsedValue))
    } catch {
      setHasFormValuesChanged(true)
    }
  }, [setHasFormValuesChanged, params, value])

  useEffect(() => {
    setValue(formatCode(params))
  }, [params])

  return (
    <Flex vertical gap='middle'>
      <Alert showIcon message={t('common:operations.DebugParams.workInProgressMessage')} type='info'/>
      <ParamsStep
        title={t('common:settings')}
        checks={Object.keys(failedChecks)}
      >

        {isEditingEnable ? (
          <Flex vertical gap='small'>
            <Input.TextArea
              value={value}
              autoSize={{minRows: 4, maxRows: 8}}
              onChange={handleChange}
            />

            {validationMessagesKeys.map(key => (
              <ValidationMessages
                key={key}
                validationMessages={validationMessages[key]}
              />
            ))}

            <Button
              type='primary'
              disabled={isSaveDisabled}
              onClick={submit}
            >
              {t('common:button:save')}
            </Button>
          </Flex>
        ) : (
          <CodeBlock
            code={formatCode(JSON.parse(value))}
            isCopyable={false}
          />
        )}
      </ParamsStep>

      {preparationContext?.result?.checks.length > 0 && (
        <PreparationChecks result={preparationContext?.result}/>
      )}
    </Flex>
  )
}

export default Params
