import {useCallback, useContext, useMemo, useTransition} from 'react'

import {IdcardOutlined} from '@ant-design/icons'
import {Button, Input, Radio, Space, Form, Select, Typography, message} from 'antd'
import {useTranslation} from 'react-i18next'
import {useLoaderData, useNavigate} from 'react-router-dom'

import api from 'services/api/index.js'

import {getProjectUrl} from 'helpers/url.js'

import {removeUnusedFields} from 'util/form.js'

import {UserContext} from 'contexts/user.js'

import FullNameAvatar from 'components/display/avatar/full-name-avatar.js'
import OrganizationAvatar from 'components/display/avatar/organization-avatar.js'
import UserAvatar from 'components/display/avatar/user-avatar.js'
import IllustratedLayout from 'components/layouts/illustrated-layout.js'
import MarkdownTextArea from 'components/ui/markdown-text-area.js'

import Container from 'containers/layout/container.js'
import Meta from 'containers/layout/meta.js'

const {Text} = Typography

export async function newProjectLoader() {
  return api.getAccountOrganizations()
}

function NewProject() {
  const {t} = useTranslation(['translation', 'common'])

  const organizations = useLoaderData()

  const navigate = useNavigate()

  const {user, getAvatarURL} = useContext(UserContext)

  const [messageApi, contextHolder] = message.useMessage()

  const [isPending, startTransition] = useTransition()

  const [form] = Form.useForm()
  const visibility = Form.useWatch('visibility', form)

  const avatar = useMemo(() => getAvatarURL(), [getAvatarURL])

  const ownerSelectOptions = useMemo(() => {
    const userOption = {
      value: user._id,
      label: (
        <FullNameAvatar fullName={user.fullName} avatarPosition='left'>
          <UserAvatar avatar={avatar} size='default'/>
        </FullNameAvatar>
      )
    }

    if (organizations?.length === 0) {
      return [userOption]
    }

    return [
      {
        label: t('NewProject.userLabel'),
        options: [userOption]
      },
      {
        label: t('NewProject.organizationsLabel'),
        options: organizations.map(organization => ({
          value: organization._id,
          label: (
            <FullNameAvatar fullName={organization.displayName} avatarPosition='left'>
              <OrganizationAvatar avatar={api.getAvatarURL(organization, 'small')} size='default'/>
            </FullNameAvatar>
          )
        }))
      }
    ]
  }, [avatar, user, organizations, t])

  const onFinish = useCallback(async values => {
    startTransition(async () => {
      const project = removeUnusedFields(values)

      try {
        const createdProject = values.owner === user._id
          ? await api.createAccountProject(project)
          : await api.createOrganizationProject(values.owner, project)

        navigate(getProjectUrl(createdProject))
      } catch (error) {
        messageApi.error(t('NewProject.submitError', {error: error.message}))
      }
    })
  }, [messageApi, t, navigate, user])

  return (
    <Container>
      {contextHolder}
      <Meta title={t('NewProject.pageTitle')}/>

      <IllustratedLayout
        title={t('NewProject.formTitle')}
        iconSrc='/images/project-logo.png'
        illustrationSrc='/images/project-create-illustration.svg'
        illustrationAlt={t('NewProject.illustrationAlt')}
      >
        <Form
          form={form}
          layout='vertical'
          onFinish={onFinish}
        >
          <Form.Item
            name='owner'
            label={<Text strong>{t('NewProject.ownerLabel')}</Text>}
            initialValue={user._id}
            style={{marginTop: '16px'}}
          >
            <Select
              loading={!organizations}
              size='large'
              disabled={ownerSelectOptions.length === 1}
              options={ownerSelectOptions}
            />
          </Form.Item>

          <Form.Item
            name='title'
            label={<Text strong>{t('NewProject.titleLabel')}</Text>}
            style={{marginTop: '16px'}}
            rules={[
              {
                required: true,
                message: t('common:form.requiredField')
              }
            ]}
          >
            <Input placeholder={t('NewProject.titlePlaceholder')} prefix={<IdcardOutlined/>}/>
          </Form.Item>

          <Form.Item
            name='description'
            label={t('NewProject.descriptionLabel')}
          >
            <MarkdownTextArea placeholder={t('NewProject.descriptionPlaceholder')}/>
          </Form.Item>

          <Form.Item
            name='visibility'
            initialValue='private'
            help={visibility === 'private' ? t('NewProject.privateVisibilityHelp') : t('NewProject.publicVisibilityHelp')}
          >
            <Radio.Group>
              <Space direction='vertical'>
                <Radio value='public'>{t('NewProject.publicVisibilityLabel')}</Radio>
                <Radio value='private'>{t('NewProject.privateVisibilityLabel')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item>
            <Button style={{marginTop: '16px'}} type='primary' htmlType='submit' loading={isPending}>
              {t('NewProject.create')}
            </Button>
          </Form.Item>
        </Form>
      </IllustratedLayout>

    </Container>
  )
}

export default NewProject
