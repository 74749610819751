import React from 'react'

import {Flex, Typography, theme, Divider, Badge} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {getTimeSince, formatFullDateTime} from 'util/date.js'

import UsersAvatarGroup from 'components/display/avatar/users-avatar-group.js'

const {Text} = Typography

const Title = styled(Typography.Text)`
  width: fit-content;
  margin: 0;
  font-size: ${({theme}) => theme.antd.fontSizeHeading3}px;
`

const SummaryContainer = styled(Flex)`
  padding: ${({theme}) => theme.antd.paddingMD}px;

  .divider {
    margin: 0;
  }

  .content {
    background-color: ${({theme}) => theme.token.colorGray70};
    border-radius: ${({theme}) => theme.antd.borderRadius}px;
    padding: ${({theme}) => theme.antd.paddingSM}px;
  }
`

function WorkspaceSummary({status, activatedAt, stoppedAt, users}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'Project.WorkspaceCard'
  const {token} = theme.useToken()

  const endTime = stoppedAt ? new Date(stoppedAt) : null
  const since = getTimeSince(new Date(activatedAt), endTime, true)

  return (
    <SummaryContainer vertical>
      <Title>{t(`${keyPrefix}.workspaceSummaryTitle`)}</Title> {/* TODO: wordking */}
      <Flex vertical gap='large' className='content'>
        <Flex vertical>
          <Text strong>Statut :</Text>
          <Badge color={token.workspaceStatusColor[status]} text={t(`common:workspace.status.${status}`)}/>
        </Flex>

        <Flex vertical>
          <Text strong>{status === 'stopped' ? t(`${keyPrefix}.activeFor`) : t(`${keyPrefix}.activeSince`)}</Text>
          {t('common:date.since', {since})}
        </Flex>

        <Flex vertical>
          <Text strong>{t(`${keyPrefix}.startDate`)}</Text>
          {formatFullDateTime(activatedAt)}
        </Flex>

        <Flex vertical>
          <Text strong>{t(`${keyPrefix}.users`)}</Text>
          <UsersAvatarGroup profiles={users}/>
        </Flex>

        <Divider className='divider'/>

        <Flex vertical justify='center' align='center' gap='middle'>
          <Text strong italic>{t(`${keyPrefix}.resources`)}</Text>
          <img width={200} src='/images/workinprogress-illustration.svg' alt={t(`${keyPrefix}.resourcesPlaceholder`)}/>
        </Flex>
      </Flex>
    </SummaryContainer>
  )
}

WorkspaceSummary.propTypes = {
  status: PropTypes.string,
  activatedAt: PropTypes.string,
  stoppedAt: PropTypes.string,
  users: PropTypes.array
}

export default WorkspaceSummary
