/* eslint-disable react/prop-types */

import React, {useContext, useEffect, useState} from 'react'

import {Form} from 'antd'
import PropTypes from 'prop-types'

import {getValidationMessagesForField} from 'util/joi-validations.js'

import OperationParamsContext from 'contexts/operation-params-context.js'

import {TimelineContext} from 'containers/operation-params-wrapper.js'

const withEditable = (FieldComponent, DisplayComponent, formInputProps) => {
  function EditableComponent(props) {
    const {isEditingEnable, validationErrors, hasFormValuesChanged} = useContext(OperationParamsContext)
    const {t} = useContext(TimelineContext)

    const [validationMessages, setValidationMessages] = useState(null)

    useEffect(() => {
      if (validationErrors) {
        const messages = getValidationMessagesForField(props.name, validationErrors, t)
        setValidationMessages(messages)
      }
    }, [props.name, t, validationErrors])

    useEffect(() => {
      if (hasFormValuesChanged) {
        setValidationMessages(null)
      }
    }, [hasFormValuesChanged])

    if (isEditingEnable) {
      return (
        <Form layout='vertical'>
          <Form.Item
            required={props.isRequired}
            colon={false}
            label={props.label}
            name={props.name}
            validateStatus={validationMessages ? 'error' : null}
            help={validationMessages?.join(', ') || props.help}
            {...formInputProps}
          >
            <FieldComponent
              {...props}
              validationMessages={validationMessages}
            />
          </Form.Item>
        </Form>
      )
    }

    return <DisplayComponent {...props}/>
  }

  EditableComponent.propTypes = {
    isEditingEnable: PropTypes.bool
  }

  return EditableComponent
}

export default withEditable
