import React from 'react'

import {ApartmentOutlined} from '@ant-design/icons'
import {Space} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import ActiveWorkspacesCounter from 'components/display/workspaces/active-workspaces-counter.js'

import WorkspaceState from 'containers/workspaces-manager/workspace-state.js'

function WorkspaceLeftButton({leftButton, workspace, startWorkspace, activeWorkspaceCount}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project'})

  let props = {children: null}

  if (workspace._id) { // Current workspace selected
    props = {
      children: (
        <WorkspaceState
          workspaceId={workspace?._id}
          eventSource={workspace?.eventSource}
        />
      )}
  } else if (activeWorkspaceCount > 0) { // No workspace selected, but there are active workspaces
    props = {
      children: <ActiveWorkspacesCounter activeWorkspaceCount={activeWorkspaceCount}/>
    }
  } else { // No workspace selected and no active workspaces
    props = {
      children: (
        <Space>
          <ApartmentOutlined/>
          {t('WorkspaceManager.startWorkspace')}
        </Space>
      ),
      onClick: startWorkspace
    }
  }

  return React.cloneElement(leftButton, props)
}

WorkspaceLeftButton.propTypes = {
  leftButton: PropTypes.object,
  workspace: PropTypes.object,
  t: PropTypes.func
}

export default WorkspaceLeftButton
