/* eslint-disable react/prop-types */

import {BookOutlined, EnvironmentOutlined, MailOutlined, TeamOutlined} from '@ant-design/icons'
import {Divider, Flex, Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import api from 'services/api/index.js'

import FullNameAvatar from 'components/display/avatar/full-name-avatar.js'
import OrganizationAvatarGroup from 'components/display/avatar/organizations-avatar-group.js'
import UserAvatar from 'components/display/avatar/user-avatar.js'
import Markdown from 'components/markdown.js'

import ProfileInfo from './profile-info.js'

const BioContainer = styled.div`
  padding: ${({theme}) => `${theme.antd.padding}px`};
  border-radius: ${({theme}) => `${theme.antd.borderRadius}px`};
  background-color: ${({theme}) => theme.antd.contentBgWhite};
  border: 1px solid ${({theme}) => theme.antd.colorBorder};
`

const {Link} = Typography

function ProfileSummary({organizations, ...user}) {
  const {t} = useTranslation('common')

  const {fullName, location, email, company, bio} = user

  return (
    <Flex vertical>
      <FullNameAvatar fullName={fullName} avatarPosition='top'>
        <UserAvatar avatar={api.getAvatarURL(user, 'large')} size={100}/>
      </FullNameAvatar>

      <Divider/>

      <Flex vertical gap='large'>
        <ProfileInfo
          icon={EnvironmentOutlined}
          title={t('common:bio.location')}
          value={location}
        />

        <ProfileInfo
          icon={MailOutlined}
          title={t('common:bio.email')}
          value={
            email && (
              <Link href={`mailto:${email}`}>{email}</Link>
            )
          }
        />

        <ProfileInfo
          icon={TeamOutlined}
          title={t('common:bio.company')}
          value={company}
        />

        <ProfileInfo
          icon={TeamOutlined}
          title={t('common:bio.organizations')}
          value={organizations.length > 0 ? (
            <OrganizationAvatarGroup profiles={organizations}/>
          ) : t('common:bio.noOrganization')}
        />

        <ProfileInfo
          icon={BookOutlined}
          title={t('common:bio.bio')}
          value={(
            bio && (
              <BioContainer>
                <Markdown>{bio}</Markdown>
              </BioContainer>
            ))}
        />
      </Flex>
    </Flex>
  )
}

export default ProfileSummary
