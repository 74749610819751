/* eslint-disable react/prop-types */

import React, {useContext} from 'react'

import {Flex} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import WorkflowContext from 'contexts/workflow-context.js'

import OperationInfos from 'components/ui/react-flow/operation-infos.js'
import OperationState from 'components/ui/react-flow/operation-state.js'

const Container = styled(Flex)`
  padding: ${({theme}) => theme.antd.paddingXS}px;
`

const badgeStatus = {
  executed: 'success',
  failed: 'error',
  editing: 'blue',
  prepared: 'cyan',
  pending: 'processing',
  executing: 'processing',
  preparing: 'processing',
  disconnected: 'default',
  unavailable: 'default',
  idle: 'default'
}

function NodeContent({icon, title, type, tags, status, progress, isAbortable, isExecutable, onClick}) {
  const {t} = useTranslation('translation', {keyPrefix: 'CustomNode'})

  const {workspace} = useContext(WorkflowContext)

  return (
    <Container vertical gap='middle'>
      <OperationInfos
        type={type.split(':')[0]}
        icon={icon}
        title={title}
        subtitle={type}
        tags={tags}
      />

      {status && workspace.isActive && (
        <OperationState
          statusColor={badgeStatus[status] || 'default'}
          progress={progress}
          statusLabel={t(`labels.${status}`)}
          isAbortable={isAbortable}
          isExecutable={isExecutable}
          onClick={onClick}
        />
      )}
    </Container>
  )
}

NodeContent.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  tags: PropTypes.array,
  status: PropTypes.oneOf([
    'executed',
    'failed',
    'editing',
    'prepared',
    'pending',
    'executing',
    'preparing',
    'unavailable',
    'idle'
  ]),
  progress: PropTypes.object,
  isAbortable: PropTypes.bool,
  isExecutable: PropTypes.bool,
  onClick: PropTypes.func
}

export default React.memo(NodeContent)
