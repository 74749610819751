import {groupBy, mapValues} from 'lodash-es'

export function getValidationMessages(error) {
  if (!error) {
    return null
  }

  const groupedByLabel = groupBy(error.details, message => message.path[0])
  return mapValues(groupedByLabel, details => details.map(detail => detail.message))
}

export function getValidationMessagesForField(fieldName, error, t) {
  if (!error) {
    return null
  }

  const groupedByLabel = groupBy(error.details, message => message.path[0])
  const messages = groupedByLabel[fieldName]

  if (!messages) {
    return null
  }

  return messages.map(({message, path, context}) => {
    // Handle multiple paths
    if (path.length > 1) {
      const sanitizedMessage = message.replaceAll(/\[.*?]/g, '[X]') // Replace the index by a placeholder
      return t(`validations.${fieldName}.${sanitizedMessage}`, {value: context.value}) // Translate the message with the placeholder and the value
    }

    // Handle the value in the message
    if (message.includes(`"${context.value}"`)) {
      const sanitizedMessage = message.replace(context.value, '--value--') // Replace the value with a placeholder
      return t(`validations.${fieldName}.${sanitizedMessage}`, {value: context.value}) // Translate the message with the placeholder and the value
    }

    return t(`validations.${fieldName}.${message}`, {value: context.value})
  })
}

export function getValidationMessagesHelp(validationMessages, fieldName) {
  return validationMessages[fieldName] && validationMessages[fieldName].join('; ')
}
