import {useCallback, useContext, useMemo, useState} from 'react'

import {DeleteOutlined, FileOutlined} from '@ant-design/icons'
import {Button, Flex, message} from 'antd'
import {useTranslation} from 'react-i18next'
import {useLoaderData} from 'react-router-dom'

import api from 'services/api/index.js'

import ProjectContext from 'contexts/project.js'

import DeleteContainerModal from 'components/display/containers/delete-container-modal.js'
import PlaceholderMessage from 'components/display/placeholder-message.js'
import Section from 'components/layouts/section.js'
import FilesetViewerModal from 'components/ui/fileset-viewer-modal.js'

import FileListContainerSelectModal from 'containers/file-list-container/select-modal.js'
import Container from 'containers/layout/container.js'
import FilesetCardController from 'containers/project/fileset-card-controller.js'

export async function projectFileContainersLoader({params: {projectId}}) {
  const containers = await api.getContainers(projectId)
  return {projectId, containers}
}

function FileContainers() {
  const data = useLoaderData()

  const {t} = useTranslation('translation', {keyPrefix: 'Project.ProjectFiles'})
  const {isUserCanEdit} = useContext(ProjectContext)

  const [messageApi, contextHolder] = message.useMessage()

  const [containers, setContainers] = useState(data.containers)
  const [openedFileList, setOpenedFileList] = useState(null)
  const [containerToDelete, setContainerToDelete] = useState(null)
  const [fileListIdToAssign, setFileListIdToAssign] = useState(null)

  const fileLists = useMemo(() => containers.map(({fileList}) => fileList), [containers])

  const onAssignContainer = useCallback(fileListId => {
    setFileListIdToAssign(fileListId)
  }, [])

  const onDeleteContainer = useCallback(async () => {
    try {
      await api.deleteContainer(data.projectId, containerToDelete)
      const updatedContainers = await api.getContainers(data.projectId)
      setContainers(updatedContainers)
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error.message
      })
    }

    setContainerToDelete(null)
  }, [containerToDelete, messageApi, data.projectId])

  const onCreateContainer = useCallback(async newContainers => {
    try {
      await Promise.all(newContainers.map(slug => api.publishFileList(fileListIdToAssign, slug)))
      const updatedContainers = await api.getContainers(data.projectId)
      setContainers(updatedContainers)
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error.message
      })
    }

    setFileListIdToAssign(null)
  }, [fileListIdToAssign, data.projectId, messageApi])

  return (
    <Container>
      <Section
        title={t('sectionTitle')}
        icon={FileOutlined}
        link={null}
      >
        {contextHolder}

        <DeleteContainerModal
          isOpen={Boolean(containerToDelete)}
          onDelete={onDeleteContainer}
          onCancel={() => setContainerToDelete(null)}
        />

        {fileLists.length === 0 ? (
          <PlaceholderMessage
            message={t('noFilePlaceholder')}
            imgSrc='/images/container-illustration.svg'
            imgAlt={t('noFileImgAlt')}
          />
        ) : (
          <>
            <Flex vertical gap='small'>
              {containers.map(({_id, name, fileList}) => (
                <Flex key={_id} align='center' gap='small'>
                  <FilesetCardController
                    fileset={{...fileList, containers: [name]}}
                    onOpen={setOpenedFileList}
                    onAssignContainer={onAssignContainer}
                  />
                  {isUserCanEdit && (
                    <Button
                      danger
                      size='large'
                      type='primary'
                      icon={<DeleteOutlined/>}
                      onClick={() => setContainerToDelete(name)}
                    />
                  )}
                </Flex>
              ))}
            </Flex>

            {fileListIdToAssign && (
              <FileListContainerSelectModal
                fileListId={fileListIdToAssign}
                onConfirm={onCreateContainer}
                onClose={() => setFileListIdToAssign(null)}
              />
            )}

            {openedFileList && (
              <FilesetViewerModal
                fileset={openedFileList}
                onClose={() => setOpenedFileList(null)}
              />
            )}
          </>
        )}
      </Section>
    </Container>
  )
}

export default FileContainers
