import {useCallback, useEffect, useState} from 'react'

import {WarningOutlined} from '@ant-design/icons'
import {Badge, Divider, Flex, Space, Spin, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import theme from 'theme.js'

import api from 'services/api/index.js'

import Timer from 'containers/timer.js'

const {Text} = Typography

const {token} = theme

const statusProps = {
  active: {badge: 'success', color: token.workspaceStatusColor.active},
  stopped: {badge: 'error', color: token.workspaceStatusColor.stopped},
  stalled: {badge: 'warning', color: token.workspaceStatusColor.stalled},
  pending: {badge: 'processing', color: token.workspaceStatusColor.pending}
}

function WorkspaceState({workspaceId, eventSource}) {
  const {t} = useTranslation('common')

  const [workspace, setWorkspace] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState()

  const fetchWorkspace = useCallback(async () => {
    setIsLoading(true)
    setError(null)

    try {
      const workspace = await api.getWorkspace(workspaceId)
      setWorkspace(workspace)
    } catch (error) {
      setError(error)
    }

    setIsLoading(false)
  }, [workspaceId])

  useEffect(() => {
    function updateWorkspace(message) {
      if (message.type === 'status-updated') {
        setWorkspace(workspaceProps => ({...workspaceProps, status: message.status}))

        // Refresh workspace to get "activatedAt" or "stoppedAt" values
        fetchWorkspace()
      }
    }

    if (workspace?.status !== 'stopped' && eventSource) {
      eventSource.on('message', updateWorkspace)

      return () => {
        eventSource.off('message', updateWorkspace)
      }
    }
  }, [workspaceId, workspace?.status, eventSource, fetchWorkspace])

  // Fetch workspace on mount (or when workspaceId changes)
  useEffect(() => {
    fetchWorkspace()
  }, [fetchWorkspace])

  if (error) {
    return <Text type='danger'><WarningOutlined/> {error.message}</Text>
  }

  if (isLoading) {
    return <Spin size='small'/>
  }

  const {status, activatedAt, stoppedAt} = workspace
  const {badge, color} = statusProps[status]

  return (
    <Flex wrap='nowrap' gap='small' align='center'>
      <Space align='center'>
        <Badge status={badge}/>

        <Text style={{color}}>
          {t(`workspace.status.${status}`)}
        </Text>
      </Space>

      {activatedAt && (
        <>
          <Divider type='vertical'/>

          <Timer
            startTime={activatedAt}
            endTime={stoppedAt}
          />
        </>
      )}
    </Flex>
  )
}

WorkspaceState.propTypes = {
  workspaceId: PropTypes.string,
  eventSource: PropTypes.object
}

export default WorkspaceState
