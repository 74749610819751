import {createContext, useMemo, useState} from 'react'

import PropTypes from 'prop-types'

const PageContext = createContext()

export function PageContextProvider({children}) {
  const [headerNavItems, setHeaderNavItems] = useState([])

  const value = useMemo(() => ({
    headerNavItems, setHeaderNavItems
  }), [headerNavItems])

  return (
    <PageContext.Provider value={value}>
      {children}
    </PageContext.Provider>
  )
}

PageContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default PageContext
