/* eslint-disable react/prop-types */

import {Divider, Flex, Typography} from 'antd'
import {useTranslation} from 'react-i18next'

import WorkspaceCard from './workspaces-manager/workspace-card.js'

const {Text} = Typography

function filterStoppedWorkspaces(workspaces) {
  const filtered = []
  const rest = []

  for (const workspace of workspaces) {
    if (workspace.status === 'stopped') {
      filtered.push(workspace)
    } else {
      rest.push(workspace)
    }
  }

  return [filtered, rest]
}

function WorkspacesList({workspaces}) {
  const {t} = useTranslation('common', {keyPrefix: 'workspace.status'})

  const [stoppedWorkspaces, otherWorkspaces] = filterStoppedWorkspaces(workspaces)

  return (
    <Flex vertical flex={1} gap='large'>
      {otherWorkspaces.map(workspace => (
        <WorkspaceCard key={workspace._id} {...workspace}/>
      ))}

      {stoppedWorkspaces.length > 0 && (
        <Flex vertical gap='small'>

          {otherWorkspaces.length > 0 && (
            <>
              <Divider style={{margin: 0}}/>
              <Text strong>{t('stopped', {count: stoppedWorkspaces.length})}</Text>
            </>
          )}

          {stoppedWorkspaces.map(workspace => (
            <WorkspaceCard key={workspace._id} {...workspace}/>
          ))}
        </Flex>
      )}
    </Flex>
  )
}

export default WorkspacesList
