/* eslint-disable react/prop-types */

import {useContext, useEffect, useState} from 'react'

import {Typography, Alert, Divider, Flex, Modal, Select, Tag} from 'antd'
import {useTranslation} from 'react-i18next'

import api from 'services/api/index.js'

import ProjectContext from 'contexts/project.js'

const {Text} = Typography

const styles = {
  select: {
    width: '100%'
  }
}

function normalizeString(input) {
  let normalized = input.toLowerCase()

  // Replace non-alphanumeric and non-dash characters with dashes
  normalized = normalized.replaceAll(/[^a-z\d]+/g, '-')

  // Make sure there are no consecutive dashes
  normalized = normalized.replaceAll(/-+/g, '-')

  return normalized
}

const containersToOptions = containers => containers.map(container => ({
  label: container.name,
  value: container.name
}))

function FileListContainerSelectModal({fileListId, onConfirm, onClose}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'FileList.FileListContainerSelectModal'

  const {project} = useContext(ProjectContext)

  const [containers, setContainers] = useState([])
  const [value, setValue] = useState([])
  const [options, setOptions] = useState(null)
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    async function fetchContainers() {
      const containers = await api.getContainers(project._id)

      setContainers(containers)
      setOptions(containersToOptions(containers.filter(({fileList}) => fileList._id !== fileListId)))
    }

    fetchContainers()
  }, [project._id, fileListId])

  const handleSearch = value => setSearchValue(normalizeString(value))

  const handleChange = values => {
    setValue(values.map(value => ({
      label: value.replaceAll(/^-+|-+$/g, ''), // Remove dashes at the beginning and end of the string
      value
    })))
    setSearchValue('')
  }

  const handleConfirm = () => {
    onConfirm(value.map(({value}) => value))
  }

  return (
    <Modal
      open
      title={t(`${keyPrefix}.selectContainerTitle`)}
      okText={t('common:button:validate')}
      cancelText={t('common:button:cancel')}
      onOk={handleConfirm}
      onCancel={onClose}
    >
      <Flex vertical gap='middle'>
        <Text strong>Ces fichiers sont déjà disponibles via les conteneurs suivants</Text>
        <Flex>
          {containers
            .filter(({fileList}) => fileList._id === fileListId)
            .map(({name}) => (
              <Tag key={name}>{name}</Tag>
            ))}
        </Flex>

        <Alert
          showIcon
          message={t(`${keyPrefix}.assignContainerWarningDescription`)} /* TODO: wording */
          type='warning'
        />

        <Divider/>

        <Text strong>Sélectionnez les autres conteneurs à ajouter </Text>
        <Select
          loading={!options}
          mode='tags'
          style={styles.select}
          value={value}
          searchValue={searchValue}
          placeholder={t(`${keyPrefix}.selectContainerPlaceholder`)}
          options={options}
          notFoundContent={t(`${keyPrefix}.noContainerFound`)}
          onSearch={handleSearch}
          onChange={handleChange}
        />

        <Alert
          showIcon
          message={t(`${keyPrefix}.assignContainerMessageDescription`)} /* TODO: wording */
          type='info'
        />
      </Flex>
    </Modal>
  )
}

export default FileListContainerSelectModal
