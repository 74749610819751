import {useContext, useEffect, useState} from 'react'

import {Flex} from 'antd'
import {useLoaderData, useParams} from 'react-router-dom'

import api from 'services/api/index.js'

import {UserContext} from 'contexts/user.js'

import Section from 'components/layouts/section.js'
import SidebarLayout from 'components/layouts/sidebar-layout.js'
import ProfileSummary from 'components/user/profile-summary.js'

import Meta from 'containers/layout/meta.js'
import OrganizationsSection from 'containers/organizations/organizations-section.js'
import ProjectsSection from 'containers/projects/projects-section.js'

export async function profileLoader({params}) {
  const [profileAccount, userOrganizations, projects] = await Promise.all([
    api.getUser(params.userId),
    api.getUserOrganizations(params.userId),
    api.getUserProjects(params.userId)
  ])

  const organizations = await Promise.all(userOrganizations.map(organization => api.getOrganization(organization._id)))

  return {profileAccount, organizations, projects}
}

function Profile() {
  const loaderData = useLoaderData()

  const {user} = useContext(UserContext)
  const {userId} = useParams()

  const isOwner = user._id === loaderData.profileAccount._id

  const [profileAccount, setProfileAccount] = useState(loaderData.profileAccount)

  useEffect(() => {
    const updateProfileAccount = async () => {
      const profileAccount = await api.getUser(userId)
      setProfileAccount(profileAccount)
    }

    updateProfileAccount()
  }, [userId])

  return (
    <>
      <Meta title={profileAccount.fullName} description={profileAccount.bio}/>

      <SidebarLayout
        isSidebarFirst
        main={(
          <Flex vertical gap='large'>
            <ProjectsSection
              projects={loaderData.projects}
              isUserMember={isOwner}
            />
            <OrganizationsSection
              organizations={loaderData.organizations}
            />
          </Flex>
        )}
        sidebar={(
          <Section>
            <ProfileSummary
              {...profileAccount}
              organizations={loaderData.organizations}
            />
          </Section>
        )}
      />
    </>
  )
}

export default Profile
