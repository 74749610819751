/* eslint-disable react/prop-types */

import {UserOutlined} from '@ant-design/icons'
import {Avatar} from 'antd'

function UserAvatar({avatar, size = 'default'}) {
  return (
    <Avatar
      src={avatar || '/images/default-user-avatar.png'}
      alt='avatar'
      size={size}
      icon={<UserOutlined/>}
    />
  )
}

export default UserAvatar

