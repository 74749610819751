/* eslint-disable react/prop-types */

import {useEffect, useState, useContext} from 'react'

import {Spin, Alert} from 'antd'
import {orderBy} from 'lodash-es'
import {useTranslation} from 'react-i18next'

import api from 'services/api/index.js'

import WorkflowContext from 'contexts/workflow-context.js'

import SidebarSection from 'components/layouts/sidebar-section.js'

import WorkspacesList from 'containers/workspaces-list.js'

const LIST_DISPLAY_LIMIT = 3

function WorkspacesSection({isUserCanEdit = false, showWorkspacesList}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'Project.WorkflowEditor.WorkspacesSection'

  const {workflow, workspace} = useContext(WorkflowContext)
  const {startWorkspace} = workspace

  const [workspaces, setWorkspaces] = useState([])
  const [isWorkspacesLoading, setIsWorkspacesLoading] = useState(true)
  const [error, setError] = useState(null)

  const hasWorkspaces = isWorkspacesLoading === false && workspaces.length > 0
  const isFullListDisplay = hasWorkspaces && workspaces.length < LIST_DISPLAY_LIMIT

  useEffect(() => {
    const getWorkflowWorkspaces = async () => {
      setIsWorkspacesLoading(true)
      setError(null)

      try {
        const workspaces = await api.getWorkflowWorkspaces(workflow._id)
        const orderedWorkspaces = orderBy(workspaces, ['createdAt'], ['desc'])
        setWorkspaces(orderedWorkspaces)
      } catch (error) {
        setError(error)
      }

      setIsWorkspacesLoading(false)
    }

    getWorkflowWorkspaces()
  }, [workflow._id])

  const handleStartWorkspace = async () => {
    try {
      await startWorkspace()
    } catch (error) {
      setError(error)
    }
  }

  const getActionButton = () => {
    if (!isWorkspacesLoading) {
      if (!hasWorkspaces && isUserCanEdit) {
        return handleStartWorkspace
      }

      if (!isFullListDisplay) {
        return () => showWorkspacesList(workspaces)
      }
    }
  }

  return (
    <SidebarSection
      title={t(`${keyPrefix}.title`)}
      isPlaceholderDisplayed={!hasWorkspaces}
      placeholderImage='/images/workspaces-illustration.svg'
      placeholderImageAlt={t(
        `${keyPrefix}.workspacesIllustrationAlt`
      )}
      actionButtonLabel={hasWorkspaces && !isFullListDisplay ? t('common:button.showAll') : t(`${keyPrefix}.startWorkspace`)}
      handleAction={getActionButton()}
    >
      {isWorkspacesLoading && <Spin/>}

      {error ? (
        <Alert showIcon message='Error' description={error.message} type='error'/>
      ) : (
        <WorkspacesList workspaces={workspaces.slice(0, LIST_DISPLAY_LIMIT)}/>
      )}
    </SidebarSection>
  )
}

export default WorkspacesSection
