/* eslint-disable react/prop-types */

import {useContext} from 'react'

import {Flex, Typography, Select as SelectAntd, Tag} from 'antd'
import {useTranslation} from 'react-i18next'

import OperationParamsContext from 'contexts/operation-params-context.js'

import withEditable from 'hoc/with-editable.js'

import useField from 'hooks/use-field.js'

const {Text} = Typography

function Display({label, name}) {
  const {t} = useTranslation('common')
  const {params, initialParams} = useContext(OperationParamsContext)
  const value = params[name] || initialParams[name]

  return (
    <Flex vertical>
      <Text>{label} :</Text>
      <Flex>
        {value ? (
          <Tag>{value}</Tag>
        ) : (
          <Text italic>{t('notSpecified')}</Text>
        )}
      </Flex>
    </Flex>
  )
}

function Field({name, options}) {
  const {params, initialParams, updateParam} = useContext(OperationParamsContext)

  const {value, initialValue, onChangeValue} = useField({
    name,
    initialValue: params ? params[name] : initialParams[name],
    submit: updateParam
  })

  const handleChange = value => {
    onChangeValue(value)
    updateParam({name, value})
  }

  return (
    <SelectAntd
      value={value}
      defaultValue={initialValue}
      options={options}
      onChange={handleChange}
    />
  )
}

export default withEditable(Field, Display)
