import React from 'react'

import {CloseOutlined} from '@ant-design/icons'
import {Flex, Typography, Button} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SectionWrapper = styled(Flex)`
  .content-wrapper {
    width: 100%;
    padding: 0 ${({theme}) => theme.antd.paddingMD}px;
  }

  .close-icon {
    font-size: 1.5rem;
    cursor: pointer;
  }
`

const HeaderWrapper = styled(Flex)`
  position: sticky;
  top: 0;
  background-color: ${({theme}) => theme.antd.contentBgWhite};
  padding: ${({theme}) => theme.antd.paddingSM}px ${({theme}) => theme.antd.paddingMD}px;
  z-index: 1;
`

const ContentWrapper = styled.div`
  background-color: ${({theme}) => theme.antd.contentBgLight};
  border-radius: ${({theme}) => theme.antd.borderRadius}px;
  padding: ${({theme}) => theme.antd.paddingMD}px;
`

function SectionExtendedLayout({title, onClose, children}) {
  return (
    <SectionWrapper vertical gap=''>
      <HeaderWrapper justify='space-between' align='center'>
        <Typography.Text strong>{title}</Typography.Text>
        <Button
          type='text'
          icon={<CloseOutlined/>}
          size='large'
          onClick={onClose}
        />
      </HeaderWrapper>

      <div className='content-wrapper'>
        <ContentWrapper>
          {children}
        </ContentWrapper>
      </div>
    </SectionWrapper>
  )
}

SectionExtendedLayout.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default SectionExtendedLayout
