
import {useContext, useEffect} from 'react'

import {useLoaderData} from 'react-router-dom'

import {getUserUrl} from 'helpers/url.js'

import PageContext from 'contexts/page-context.js'

function UserLayout({children}) {
  const {profileAccount} = useLoaderData()

  const {setHeaderNavItems} = useContext(PageContext)

  useEffect(() => {
    setHeaderNavItems([
      {title: profileAccount.fullName, path: getUserUrl(profileAccount)}
    ])
  }, [profileAccount, setHeaderNavItems])

  return children
}

export default UserLayout
