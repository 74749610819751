/* eslint-disable react/prop-types */

import {useCallback, useEffect, useState} from 'react'

import {message} from 'antd'

import api from 'services/api/index.js'

import FilesetViewerModal from 'components/ui/fileset-viewer-modal.js'

import FileListContainerSelectModal from 'containers/file-list-container/select-modal.js'
import FilesetCardController from 'containers/project/fileset-card-controller.js'

export function Execution({executionContext}) {
  const {fileListId} = executionContext

  const [fileList, setFileList] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [fileListIdToAssign, setFileListIdToAssign] = useState(null)

  const [messageApi, contextHolder] = message.useMessage()

  const updateFileList = useCallback(async () => {
    const fileLists = await api.getFileList(fileListId)
    setFileList(fileLists)
  }, [fileListId])

  useEffect(() => {
    if (fileListId) {
      updateFileList()
    }
  }, [fileListId, updateFileList])

  const onAssignContainer = useCallback(fileListId => {
    setFileListIdToAssign(fileListId)
  }, [])

  const onCreateContainer = useCallback(async containers => {
    const containersToAdd = containers.filter(Slug => !fileList.containers.map(({name}) => name).includes(Slug))
    try {
      await Promise.all(containersToAdd.map(containerSlug => api.publishFileList(fileListIdToAssign, containerSlug)))
      updateFileList()
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error.message
      })
    }

    setFileListIdToAssign(null)
  }, [fileList, fileListIdToAssign, messageApi, updateFileList])

  return (
    <>
      {contextHolder}

      {fileListIdToAssign && (
        <FileListContainerSelectModal
          fileListId={fileListIdToAssign}
          onConfirm={onCreateContainer}
          onClose={() => setFileListIdToAssign(null)}
        />
      )}

      {fileList && (
        <FilesetCardController
          fileset={fileList}
          onOpen={() => setIsOpen(true)}
          onAssignContainer={onAssignContainer}
        />
      )}

      {isOpen && (
        <FilesetViewerModal
          fileset={fileList}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  )
}
