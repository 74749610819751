/* eslint-disable react/prop-types */

import React, {useCallback} from 'react'

import {DeleteOutlined, UserOutlined, RobotOutlined} from '@ant-design/icons'
import {Flex, Space, Typography, Avatar, Button, Card} from 'antd'
import {useTranslation} from 'react-i18next'

import {getTimeSince} from 'util/date.js'

import UsersAvatarGroup from 'components/display/avatar/users-avatar-group.js'
import ContainerTags from 'components/display/filelist/container-tags.js'
import Counters from 'components/ui/fileset-explorer/counters.js'

const {Text} = Typography

const formatParams = {
  date: {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'}
}

const styles = {
  card: {
    width: '100%'
  }
}

function FilesetCard({
  type,
  containers,
  isLocked,
  createdAt,
  updatedAt,
  members,
  counters,
  isDeleteDisabled,
  onDelete,
  onClose,
  onAssignContainer,
  onOpen
}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'FilesetCard'

  const handleOpen = useCallback(event => {
    event.stopPropagation()
    event.preventDefault()
    onOpen()
  }, [onOpen])

  return (
    <Card bordered={isLocked} style={styles.card}>
      <Flex vertical gap='middle'>
        <Flex
          justify='space-between'
          align='flex-start'
          gap='small'
          wrap='wrap'
        >
          <Flex gap='small' wrap='wrap'>
            <Avatar
              size='large'
              icon={type === 'user' ? <UserOutlined/> : <RobotOutlined/>}
            />
            <div>
              <Text type='secondary'>
                {t(`${keyPrefix}.title`,
                  {
                    date: new Date(createdAt),
                    formatParams
                  })}
              </Text>
              <br/>
              <Text type='secondary'>
                {t('common:date.updatedSince')}{t('common:date.since', {since: getTimeSince(updatedAt)})}
              </Text>
            </div>
          </Flex>

          <UsersAvatarGroup profiles={members}/>
        </Flex>

        <Counters {...counters}/>

        <Flex
          justify='space-between'
          align='center'
          wrap='wrap'
          gap='small'
        >
          {isLocked ? (
            <ContainerTags
              containers={containers}
              onAssign={onAssignContainer}
            />
          ) : (
            <Button
              danger
              type='primary'
              icon={<DeleteOutlined/>}
              disabled={isDeleteDisabled}
              onClick={onDelete}
            />
          )}

          <Space>
            {!isLocked && (
              <Button
                type='primary'
                onClick={onClose}
              >
                {t(`${keyPrefix}.sealedButtonLabel`)}
              </Button>
            )}
            <Button onClick={handleOpen}>{t(`${keyPrefix}.showButtonLabel`)}</Button>
          </Space>
        </Flex>
      </Flex>
    </Card>
  )
}

export default React.memo(FilesetCard)
