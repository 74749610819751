/* eslint-disable react/prop-types */

import {Badge, Space, Typography} from 'antd'
import {useTranslation} from 'react-i18next'

const {Text} = Typography

function ActiveWorkspacesCounter({activeWorkspaceCount}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.WorkspacesCount'})

  return (
    <Space>
      <Badge status='success'/>
      <Text strong>{activeWorkspaceCount}</Text>
      <Text> {t('workspaces', {count: activeWorkspaceCount})}</Text>
    </Space>
  )
}

export default ActiveWorkspacesCounter
