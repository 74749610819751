
import {Flex, Typography} from 'antd'
import {useTranslation} from 'react-i18next'

import SidebarSection from 'components/layouts/sidebar-section.js'

const {Text} = Typography

function WorkflowExecutionsSection() {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.WorkflowEditor.WorkflowExecutionsSection'})
  return (
    <SidebarSection
      isPlaceholderDisplayed
      title={t('title')}
      placeholderImage='/images/executions-illustration.svg'
      placeholderImageAlt={t('executionIllustrationAlt')}
    >
      <Flex justify='center'>
        <Text italic type='secondary'>{`${t('noLastExecutions')}`}</Text>
      </Flex>
    </SidebarSection>

  )
}

export default WorkflowExecutionsSection
