import {useTranslation} from 'react-i18next'
import {useLoaderData} from 'react-router-dom'

import api from 'services/api/index.js'

import Container from 'containers/layout/container.js'
import Meta from 'containers/layout/meta.js'
import ProjectsSection from 'containers/projects/projects-section.js'

export async function accountProjectsLoader() {
  const userProjects = await api.getAccountProjects()
  return {userProjects}
}

function AccountProjects() {
  const {t} = useTranslation('translation', {keyPrefix: 'Projects'})

  const {userProjects} = useLoaderData()

  return (
    <>
      <Meta title={t('pageTitle')}/>
      <Container>
        <ProjectsSection
          isUserMember
          isUserAdmin
          projects={userProjects}
        />
      </Container>
    </>
  )
}

export default AccountProjects
