import React, {createContext, useCallback, useEffect, useMemo, useState} from 'react'

import {isEqual} from 'lodash-es'
import PropTypes from 'prop-types'

const OperationParams = createContext(null)

export function OperationParamsProvider({
  operationId,
  initialParams,
  defaultValues,
  isEditingEnable,
  validationErrors,
  handleUpdate,
  children
}) {
  const [params, setParams] = useState(initialParams || defaultValues || {})
  const [hasFormValuesChanged, setHasFormValuesChanged] = useState(false)

  const updateParam = useCallback(async ({name, value}) => {
    const updatedParams = {...params, [name]: value}
    if (validationErrors || !isEqual(updatedParams, initialParams)) {
      await handleUpdate(updatedParams)
    }
  }, [validationErrors, handleUpdate, params, initialParams])

  const updateParams = useCallback(async updatedParams => {
    if (!isEqual(updatedParams, initialParams)) {
      await handleUpdate(updatedParams)
    }
  }, [handleUpdate, initialParams])

  // Reset the form values when the operation changes
  useEffect(() => {
    setParams(initialParams || defaultValues || {})
    setHasFormValuesChanged(false)
  }, [operationId, initialParams, defaultValues])

  useEffect(() => {
    const hasChanged = !isEqual(params, initialParams || defaultValues || {})
    setHasFormValuesChanged(hasChanged)
  }, [params, initialParams, defaultValues])

  const value = useMemo(() => ({
    params,
    updateParam,
    updateParams,
    handleUpdate,
    initialParams: initialParams ? {...defaultValues, ...initialParams} : defaultValues || {},
    defaultValues: defaultValues || {},
    isEditingEnable,
    validationErrors,
    setHasFormValuesChanged,
    hasFormValuesChanged
  }), [params, hasFormValuesChanged, isEditingEnable, validationErrors, initialParams, defaultValues, handleUpdate, updateParam, updateParams])

  return (
    <OperationParams.Provider value={value}>
      {children}
    </OperationParams.Provider>
  )
}

OperationParamsProvider.propTypes = {
  operationId: PropTypes.string.isRequired,
  initialParams: PropTypes.object,
  defaultValues: PropTypes.object,
  isEditingEnable: PropTypes.bool,
  validationErrors: PropTypes.object,
  handleUpdate: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default OperationParams
