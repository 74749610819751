/* eslint-disable react/prop-types */

import React, {useEffect, useState} from 'react'

import api from 'services/api/index.js'

import FilesetCard from 'components/display/fileset-card.js'

function FilesetCardController({fileset, onOpen, onCloseFileset, onAssignContainer, onDelete}) {
  const [members, setMembers] = useState([])

  const {_id, status, createdAt, updatedAt, users, itemCount, itemsSize} = fileset

  const isLocked = status === 'closed'

  useEffect(() => {
    async function fetchMembers() {
      const members = await Promise.all(users.map(user => api.getUser(user)))
      setMembers(members)
    }

    if (users) {
      fetchMembers()
    }
  }, [_id, users])

  const handleAssignContainer = () => {
    onAssignContainer(fileset._id)
  }

  const handleDelete = () => {
    onDelete(fileset._id)
  }

  return (
    <FilesetCard
      key={_id}
      type={fileset.type}
      containers={fileset.containers}
      isLocked={isLocked}
      createdAt={createdAt}
      updatedAt={updatedAt}
      members={members}
      counters={{
        folderCount: 0,
        fileCount: itemCount,
        totalSize: itemsSize
      }}
      onAssignContainer={onAssignContainer ? handleAssignContainer : null}
      onClose={onCloseFileset}
      onDelete={handleDelete}
      onOpen={() => onOpen(fileset)}
    />
  )
}

export default React.memo(FilesetCardController)
