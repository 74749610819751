/* eslint-disable react/prop-types */

import {TeamOutlined} from '@ant-design/icons'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {getNewOrganizationUrl} from 'helpers/url.js'

import Section from 'components/layouts/section.js'

import OrganizationsList from './organizations-list.js'

function OrganizationsSection({organizations, isUserMember = false, isUserAdmin = false}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Organizations'})

  const navigate = useNavigate()

  const addOrganization = () => {
    navigate(getNewOrganizationUrl())
  }

  return (
    <Section
      title={t('pageTitle')}
      icon={TeamOutlined}
      buttonLabel={t('createOrganizationButtonLabel')}
      isLoading={organizations === null}
      onAdd={isUserAdmin ? addOrganization : null}
    >
      <OrganizationsList
        organizations={organizations}
        isUserMember={isUserMember}
      />
    </Section>
  )
}

export default OrganizationsSection
