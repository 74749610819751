// Antd document for design token : https://ant.design/docs/react/customize-theme#design-token
import {green, red, orange, blue} from '@ant-design/colors'

export const nodeDimensions = {
  width: 300,
  height: 100
}

// Definition of the base color palette. Used directly in the theme and as a base for other color derivatives
const customColors = {
  // Primary
  colorPrimary: '#304358',
  colorPrimaryBlue: '#0D5A9E',
  colorPrimaryGreen: '#1B7A66',
  colorPrimaryOrange: '#FA8C16',
  colorPrimaryPurple: '#722ED1',
  colorPrimaryYellow: '#FFDD00',
  colorPrimaryBrightBlue: '#1677FF',

  // Secondary
  colorSecondaryGreen: '#3EA085',

  // Semantic
  colorSuccess: green.primary,
  colorError: red.primary,
  colorWarning: orange.primary,
  colorProcessing: blue.primary,

  // Neutral
  colorWhite: '#FFFFFF',
  colorGray70: '#F7F7F7',
  colorGray80: '#F0F0F0',
  colorGray90: '#D9D9D9',
  colorGray100: '#838E9B'
}

const colorsGradients = {
  colorGradientPrimary: `linear-gradient(to right, ${customColors.colorPrimaryBlue}, ${customColors.colorPrimaryGreen})`,
  colorGradientBadge: `linear-gradient(to right, ${customColors.colorPrimaryYellow}, ${customColors.colorPrimaryOrange})`
}

const customAlias = {
  contentBgWhite: customColors.colorWhite,
  contentBgLight: customColors.colorGray70,
  contentBgPrimary: customColors.colorPrimaryBlue,
  colorBGAvatar: customColors.colorGray90,
  boxShadowSiderLeft: '10px 14px 25px -20px rgba(0,0,0,0.2)',
  boxShadowSiderRight: '-10px 14px 25px -20px rgba(0,0,0,0.2)',
  siderToggleHeight: 48,
  textAreaHeight: 200,
  badgeRadius: 20,
  fontSizeIconLG: 48,
  tagAdminColor: 'blue',
  tagMemberColor: 'magenta'
}

const operationTypeColor = {
  inputColor: customColors.colorPrimaryPurple,
  outputColor: customColors.colorPrimaryOrange,
  fsColor: customColors.colorPrimaryGreen,
  readColor: customColors.colorPrimaryBrightBlue,
  writeColor: customColors.colorSecondaryGreen,
  transformColor: customColors.colorPrimaryYellow
}

const workspaceStatusColor = {
  active: customColors.colorSuccess,
  stopped: customColors.colorError,
  pending: customColors.colorPrimaryBlue,
  stalled: customColors.colorWarning
}

const nodeHandleAlias = {
  nodeHandleWidth: 12,
  nodeHandleHeight: 18
}

const nodeEdgeAlias = {
  unavailableEdgeColor: customColors.colorGray100,
  completedEdgeColor: customColors.colorSuccess,
  processingEdgeColor: customColors.colorPrimaryBlue,
  failedEdgeColor: customColors.colorError
}

const fontWeight = {
  fontWeightLight: 200
}

const lineHeight = {
  lineHeightLG: 2
}

// Overriding Ant Design tokens to customize base values, such as information and text colors.
const seedTokens = {
  colorTextBase: customColors.colorPrimary
}

// Overriding map tokens to adjust calculated values in Ant Design, used to customize the appearance of certain elements based on the base tokens.
const mapTokens = {
  colorWhite: customColors.colorWhite,
  colorBgLayout: customAlias.contentBgLight,
  colorPrimaryBg: customColors.colorGray80,
  colorLinkHover: customColors.colorPrimaryBlue
}

// Token aliases providing common styles for components, facilitating a consistent appearance throughout the application.
const aliasTokens = {
  boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.2)',
  boxShadowBottom: `0px 8px 8px -8px ${customColors.colorGray90}`,
  colorTextHeading: '#3F3D56',
  siderAnimationDuration: 200
}

const theme = {
  token: {
    ...customColors,
    ...seedTokens,
    ...mapTokens,
    ...colorsGradients,
    ...aliasTokens,
    ...customAlias,
    ...nodeHandleAlias,
    ...nodeEdgeAlias,
    ...operationTypeColor,
    ...fontWeight,
    ...lineHeight,
    workspaceStatusColor
  },
  components: {
    SiderLayout: {
      width: 300
    },
    Collapse: {
      headerBg: customAlias.contentBgLight
    },
    Layout: {
      headerBg: mapTokens.colorWhite,
      headerPadding: '0 24px'
    },
    Menu: {
      colorBgContainer: mapTokens.colorWhite,
      activeBarHeight: '0'
    },
    OrganizationCard: {
      width: '220px'
    },
    NavHeader: {
      widthLG: '600px',
      widthMD: '420px',
      widthSM: '200px'
    },
    Table: {
      headerBg: customColors.colorPrimary,
      headerColor: mapTokens.colorWhite
    }
  }
}

export default theme

