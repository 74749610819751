import {ArrowRightOutlined, CloseOutlined} from '@ant-design/icons'
import {Button, Flex, Space, Tag, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import OperationIcon from 'components/display/operation-icon.js'

const {Text} = Typography

const OperationPanelHeaderStyled = styled(Flex)`
  gap: ${({theme}) => theme.antd.marginXS}px;

  .icon {
    font-size: 18px;
    margin-top: ${({theme}) => theme.antd.marginXXS};
  }

  .operation-type {
    font-weight: lighter;
  }

  .tag {
    margin: 0;
  }

  @media (max-width: ${({theme}) => theme.antd.screenXSMax}px) {
    .wrapped {
      flex-direction: column;
    }
  }
`
OperationPanelHeaderStyled.displayName = 'OperationPanelHeader'

function OperationPanelHeader({title, icon, badge, type, io, onClose}) {
  const {t} = useTranslation(['common'])

  return (
    <OperationPanelHeaderStyled vertical>
      <Flex justify='space-between'>
        <Space>
          <OperationIcon icon={icon} type={type}/>
          <Flex wrap='wrap' gap='small'>
            <Text className='operation-type'>
              {t(`common:operations.types.${type}`)} :
            </Text>
            <Text strong>
              {title}
            </Text>
          </Flex>
        </Space>

        <Button
          type='text'
          size='large'
          icon={<CloseOutlined/>}
          onClick={onClose}
        />
      </Flex>

      <Flex flex={1} gap='small' align='center' justify='space-between' wrap='wrap'>
        <Space>
          <Tag className='tag'>{io.input ? t(`common:operations.InputOutputType.${io.input}`) : '-'}</Tag>
          <ArrowRightOutlined/>
          <Tag className='tag'>{io.output ? t(`common:operations.InputOutputType.${io.output}`) : '-'}</Tag>
        </Space>

        <Space>
          {badge}
        </Space>
      </Flex>

    </OperationPanelHeaderStyled>
  )
}

OperationPanelHeader.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  badge: PropTypes.element,
  type: PropTypes.string.isRequired,
  io: PropTypes.shape({
    input: PropTypes.string,
    output: PropTypes.string
  }).isRequired,
  onClose: PropTypes.func.isRequired
}

export default OperationPanelHeader
