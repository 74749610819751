/* eslint-disable react/prop-types */

import {TeamOutlined} from '@ant-design/icons'
import {Avatar} from 'antd'

function OrganizationAvatar({avatar, size = 100}) {
  return (
    <Avatar
      src={avatar || '/images/default-organization-avatar.png'}
      alt='avatar'
      size={size}
      shape='square'
      icon={<TeamOutlined/>}
    />
  )
}

export default OrganizationAvatar

