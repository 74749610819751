import {useEffect, useMemo, useState} from 'react'

import {ToolOutlined} from '@ant-design/icons'
import {Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import {Outlet, useLoaderData, useNavigate} from 'react-router-dom'

import api from 'services/api/index.js'

import {getOrganizationSettingsUrl} from 'helpers/url.js'

import useSettingsTab from 'hooks/use-settings-tab.js'

import OrganizationAvatar from 'components/display/avatar/organization-avatar.js'
import SettingsLayout from 'components/layouts/settings-layout.js'

import EditableAvatar from 'containers/user/editable-avatar.js'

function OrganizationSettingsLayout() {
  const {t} = useTranslation('translation', {keyPrefix: 'OrganizationSettings'})

  const navigate = useNavigate()

  const data = useLoaderData()

  const [organization, setOrganization] = useState(data.organization)

  // Refresh the organization data when it changes
  useEffect(() => {
    setOrganization(data.organization)
  }, [data.organization])

  const {selectedTab} = useSettingsTab('general')

  const menuProps = useMemo(() => {
    const items = [
      {
        type: 'group',
        label: t('tab.account'),
        children: [
          {
            key: 'general',
            label: t('tab.general'),
            icon: <ToolOutlined/>
          }
        ]
      }
    ]

    return {
      items,
      onClick: ({key}) => navigate(getOrganizationSettingsUrl(organization, key)),
      selectedKeys: [selectedTab === 'settings' ? 'general' : selectedTab]

    }
  }, [organization, selectedTab, navigate, t])

  const updateAvatar = async avatar => {
    try {
      const updatedOrganization = avatar
        ? await api.updateOrganizationAvatar(organization._id, avatar)
        : await api.deleteOrganizationAvatar(organization._id)
      setOrganization(updatedOrganization)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <SettingsLayout
      siderHeader={(
        <EditableAvatar
          fullName={organization.displayName}
          updateAvatar={updateAvatar}
        >
          <OrganizationAvatar avatar={api.getAvatarURL(organization, 'large')}/>
        </EditableAvatar>
      )}
      menuProps={menuProps}
    >
      <Typography.Title level={2}>{t(`tab.${selectedTab}`)}</Typography.Title>
      <Outlet/>
    </SettingsLayout>
  )
}

export default OrganizationSettingsLayout
