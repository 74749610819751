/* eslint-disable react/prop-types */

import {useState} from 'react'

import {CloseOutlined, EditOutlined} from '@ant-design/icons'
import {Button, Dropdown} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import FullNameAvatar from 'components/display/avatar/full-name-avatar.js'

import AvatarUpload from './avatar-upload.js'

const FloatButton = styled(Button)`
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
`

function EditableAvatar({fullName, updateAvatar, children}) {
  const {t} = useTranslation('common')

  const [isEditing, setIsEditing] = useState(false)

  const handleUpdate = async avatar => {
    await updateAvatar(avatar)
    setIsEditing(false)
  }

  return (
    <FullNameAvatar fullName={fullName} avatarPosition='top'>
      <div style={{position: 'relative'}}>
        {isEditing ? (
          <>
            <AvatarUpload uploadAvatar={handleUpdate}>
              {children}
            </AvatarUpload>
            <FloatButton
              icon={<CloseOutlined/>}
              shape='circle'
              size='small'
              onClick={() => setIsEditing(false)}
            />
          </>
        ) : (
          <>
            {children}
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'edit',
                    label: t('common:button.edit'),
                    onClick: () => setIsEditing(true)
                  },
                  {
                    key: 'remove',
                    label: t('common:button.remove'),
                    danger: true,
                    disabled: !children,
                    onClick: () => handleUpdate()
                  }
                ]
              }}
              trigger={['click']}
              placement='bottomLeft'
            >
              <FloatButton
                icon={<EditOutlined/>}
                shape='circle'
                size='small'
              />
            </Dropdown>
          </>
        )}
      </div>
    </FullNameAvatar>
  )
}

export default EditableAvatar
