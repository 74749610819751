
import {useLoaderData} from 'react-router-dom'

import api from 'services/api/index.js'

import Container from 'containers/layout/container.js'
import OrganizationsSection from 'containers/organizations/organizations-section.js'

export async function userOrganizationLoader({params}) {
  const organizations = await api.getUserOrganizations(params.userId)

  const organizationsPromises = organizations.map(async organization => {
    const organizationWithMembers = await api.getOrganization(organization._id)
    organization.members = organizationWithMembers.members
  })

  await Promise.all(organizationsPromises)

  return {organizations}
}

function UserOrganizations() {
  const {organizations} = useLoaderData()
  return (
    <Container>
      <OrganizationsSection organizations={organizations}/>
    </Container>
  )
}

export default UserOrganizations
