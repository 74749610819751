/* eslint-disable react/prop-types */

import {useTranslation} from 'react-i18next'

import DangerZoneItem from 'components/display/danger-zone/item.js'

function DeleteOrganization({deleteOrganization}) {
  const {t} = useTranslation('translation', {keyPrefix: 'OrganizationSettings.DangerZone.delete'})

  return (
    <DangerZoneItem
      title={t('title')}
      description={t('description')}
      actionLabel={t('deleteButtonLabel')}
      onConfirm={deleteOrganization}
    />
  )
}

export default DeleteOrganization
