import {useContext} from 'react'

import {Flex} from 'antd'
import {useLoaderData} from 'react-router-dom'

import api from 'services/api/index.js'

import {UserContext} from 'contexts/user.js'

import Section from 'components/layouts/section.js'
import SidebarLayout from 'components/layouts/sidebar-layout.js'
import ProfileSummary from 'components/user/profile-summary.js'

import Meta from 'containers/layout/meta.js'
import OrganizationsSection from 'containers/organizations/organizations-section.js'
import ProjectsSection from 'containers/projects/projects-section.js'
import RequireAuth from 'containers/require-auth.js'

export async function accountLoader() {
  const [userOrganizations, projects] = await Promise.all([
    api.getAccountOrganizations(),
    api.getAccountProjects()
  ])

  const organizations = await Promise.all(userOrganizations.map(organization => api.getOrganization(organization._id)))

  return {projects, organizations}
}

function Account() {
  const {projects, organizations} = useLoaderData()

  const {user} = useContext(UserContext)

  return (
    <RequireAuth>
      <Meta title={user.fullName} description={user.bio}/>

      <SidebarLayout
        isSidebarFirst
        main={(
          <Flex vertical gap='large'>
            <ProjectsSection
              isUserMember
              isUserAdmin
              projects={projects}
            />
            <OrganizationsSection
              isUserAdmin
              isUserMember
              organizations={organizations}
            />
          </Flex>
        )}
        sidebar={(
          <Section>
            <ProfileSummary
              {...user}
              organizations={organizations}
              avatar={api.getAvatarURL(user, 'large')}
            />
          </Section>
        )}
      />
    </RequireAuth>
  )
}

export default Account
