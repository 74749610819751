export function getAccountUrl() {
  return '/account'
}

export function getProjectUrl(project, tab) {
  const url = `/projects/${project._id}`

  if (tab) {
    return `${url}/${tab}`
  }

  return url
}

export function getAccountProjectsUrl() {
  return '/account/projects'
}

export function getProjectWorkflowsUrl(project) {
  return `/projects/${project._id}/workflows`
}

export function getWorkflowUrl(project, workflow) {
  return `/projects/${project._id}/workflows/${workflow._id}`
}

export function getNewProjectUrl() {
  return '/account/projects/new'
}

export function getWorkspaceUrl(project, workflow, workspace) {
  return `/projects/${project._id}/workflows/${workflow._id}?workspaceId=${workspace._id}`
}

export function getUserUrl(user) {
  return '/users/' + user._id
}

export function getUserProjectsUrl(user) {
  return `/users/${user._id}/projects`
}

export function getUserOrganizationsUrl(user) {
  return `/users/${user._id}/organizations`
}

export function getAccountSettingsUrl(tab) {
  if (!tab || tab === 'general') {
    return '/account/settings'
  }

  return '/account/settings/' + tab
}

export function getProjectFilesContainersUrl(project) {
  return `/projects/${project._id}/files-containers`
}

export function getProjectSettingsUrl(project, tab) {
  const url = `/projects/${project._id}/settings`
  if (!tab || tab === 'general') {
    return url
  }

  return `${url}/${tab}`
}

export function getProjectDataUrl(project) {
  return `/projects/${project._id}/data`
}

export function getNewOrganizationUrl() {
  return '/account/organizations/new'
}

export function getAccountOrganizationsUrl() {
  return '/account/organizations'
}

export function getOrganizationUrl(organization) {
  return `/organizations/${organization._id}`
}

export function getOrganizatioProjectsUrl(organization) {
  return `/organizations/${organization._id}/projects`
}

export function getOrganizationMembersUrl(organization) {
  return `/organizations/${organization._id}/members`
}

export function getOrganizationSettingsUrl(organization, tab) {
  const url = `/organizations/${organization._id}/settings`

  if (!tab || tab === 'general') {
    return url
  }

  return `${url}/${tab}`
}

export function getSignInUrl() {
  return '/sign-in'
}

export function getSignUpUrl() {
  return '/sign-up'
}

export function getLogoutUrl() {
  return '/logout'
}

export function getHomeUrl() {
  return '/'
}

export function getRegisterProviderUrl(provider) {
  return `${process.env.REACT_APP_API_URL}/auth/${provider}/register`
}
