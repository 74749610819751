/* eslint-disable react/prop-types, no-unused-vars */

import ParamsStep from 'components/display/operation-params/params-step.js'

import Select from 'containers/form/select.js'
import ParamsTimeline from 'containers/params-timeline.js'

export function Params({params, inputContext, preparationContext, triggerPreparation, t}) {
  return (
    <ParamsTimeline>
      <ParamsStep
        title={t('steps.coordinates.title')}
        checks={['coordinates']}
        hint={t('steps.coordinates.hint')}
      >
        <Select
          isRequired
          name='longitude'
          label={t('params.longitude')}
          options={inputContext?.schema?.map(({name}) => (
            {value: name, label: name, disabled: name === params.latitude}
          ))}
        />
        <Select
          isRequired
          name='latitude'
          label={t('params.latitude')}
          options={inputContext?.schema?.map(({name}) => (
            {value: name, label: name, disabled: name === params.longitude}
          ))}
        />
      </ParamsStep>
    </ParamsTimeline>
  )
}
