/* eslint-disable react/prop-types */

import {useContext, useEffect, useMemo} from 'react'

import {FundProjectionScreenOutlined, HomeOutlined, SettingOutlined, TeamOutlined} from '@ant-design/icons'
import {useTranslation} from 'react-i18next'
import {useLoaderData} from 'react-router-dom'

import {getOrganizationSettingsUrl, getOrganizationMembersUrl, getOrganizatioProjectsUrl, getOrganizationUrl} from 'helpers/url.js'

import PageContext from 'contexts/page-context.js'
import {UserContext} from 'contexts/user.js'

import useTabsNavigation from 'hooks/use-tabs-navigation.js'

import TabsLayout from 'components/layouts/tabs-layout.js'

function OrganizationTabsNavigation({activeTab, children}) {
  const {t} = useTranslation('translation', {keyPrefix: 'OrganizationTabsNavigation'})

  const {setHeaderNavItems} = useContext(PageContext)

  const {user} = useContext(UserContext)
  const {organization} = useLoaderData()

  useEffect(() => {
    setHeaderNavItems([
      {title: organization.displayName, path: getOrganizationUrl(organization)}
    ])
  }, [organization, setHeaderNavItems])

  const tabs = useMemo(() => {
    const tabs = {
      overview: {icon: HomeOutlined, url: getOrganizationUrl(organization)},
      projects: {icon: FundProjectionScreenOutlined, url: getOrganizatioProjectsUrl(organization)}
    }

    if (organization.members.some(member => member._id === user._id)) { // User is a member of the organization
      tabs.members = {icon: TeamOutlined, url: getOrganizationMembersUrl(organization)}
    }

    if (organization.members.some(member => member._id === user._id && member.role === 'admin')) { // User is an admin of the organization
      tabs.settings = {icon: SettingOutlined, url: getOrganizationSettingsUrl(organization)}
    }

    return tabs
  }, [organization, user])

  const {tabsItems, onTabChange} = useTabsNavigation({tabs, t})

  return (
    <TabsLayout
      tabs={tabsItems}
      activeTab={activeTab}
      onChangeTab={onTabChange}
    >
      {children}
    </TabsLayout>
  )
}

export default OrganizationTabsNavigation
