/* eslint-disable react/prop-types */

import {FundProjectionScreenOutlined} from '@ant-design/icons'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {getNewProjectUrl} from 'helpers/url.js'

import Section from 'components/layouts/section.js'

import ProjectsList from 'containers/projects/projects-list.js'

function ProjectsSection({projects, isUserMember, isUserAdmin}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Projects'})

  const navigate = useNavigate()

  const addProject = () => {
    navigate(getNewProjectUrl())
  }

  return (
    <Section
      title={t('projectsListTitle')}
      icon={FundProjectionScreenOutlined}
      buttonLabel={t('createProjectButtonLabel')}
      isLoading={projects === null}
      onAdd={isUserAdmin ? addProject : null}
    >
      <ProjectsList projects={projects} isUserMember={isUserMember}/>
    </Section>
  )
}

export default ProjectsSection
