/* eslint-disable react/prop-types */

import {PlusOutlined} from '@ant-design/icons'
import {Flex, Tag, Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const TagPlusStyled = styled(Tag)`
    background: ${({theme}) => theme.antd.colorBgContainer};
    border-style: dashed;

    &:hover {
      cursor: pointer;
    }
`

const styles = {
  tag: {
    margin: 0
  }
}

function ContainerTags({containers, onAssign}) {
  const {t} = useTranslation(['translation'], {keyPrefix: 'FilesetCard.ContainerTags'})

  return (
    <Flex wrap='wrap' gap='small'>
      {containers.map(slug => {
        const isLongTag = slug.length > 20
        const tagElem = (
          <Tag
            key={slug}
            style={styles.tag}
          >
            {isLongTag ? `${slug.slice(0, 20)}...` : slug}
          </Tag>
        )
        return isLongTag ? (
          <Tooltip key={slug} title={slug}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        )
      })}

      {onAssign && (
        <TagPlusStyled icon={<PlusOutlined/>} onClick={onAssign}>
          {t('addTagLabel')}
        </TagPlusStyled>
      )}
    </Flex>
  )
}

export default ContainerTags
