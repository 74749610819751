import {ContainerOutlined, LogoutOutlined, ToolOutlined, UserOutlined} from '@ant-design/icons'
import {Dropdown, theme} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import {getLogoutUrl, getAccountProjectsUrl, getAccountSettingsUrl, getAccountUrl} from 'helpers/url.js'

import FullNameAvatar from 'components/display/avatar/full-name-avatar.js'
import UserAvatar from 'components/display/avatar/user-avatar.js'

function UserDropdown({user, avatar}) {
  const {t} = useTranslation('translation', {keyPrefix: 'UserDropdown'})

  const {token} = theme.useToken()

  const items = [
    {
      key: 'profile',
      label: (
        <Link to={getAccountUrl()}>
          {t('profile')}
        </Link>
      ),
      icon: <UserOutlined/>
    },
    {
      key: 'settings',
      type: 'settings',
      label: (
        <Link to={getAccountSettingsUrl()}>
          {t('settings')}
        </Link>
      ),
      icon: <ToolOutlined/>
    },
    {
      key: 'projects',
      label: (
        <Link to={getAccountProjectsUrl()}>
          {t('projects')}
        </Link>
      ),
      icon: <ContainerOutlined/>
    },
    {type: 'divider'},
    {
      key: 'logout',
      label: (
        <Link to={getLogoutUrl()}>
          {t('logout')}
        </Link>
      ),
      icon: <LogoutOutlined/>
    }
  ]

  return (
    <Dropdown menu={{items}} placement='bottomRight' overlayStyle={{paddingTop: `${token.padding}px`}} trigger='click'>
      <a onClick={e => e.preventDefault()}>
        <FullNameAvatar fullName={user.fullName}>
          <UserAvatar avatar={avatar}/>
        </FullNameAvatar>
      </a>
    </Dropdown>
  )
}

UserDropdown.propTypes = {
  user: PropTypes.object,
  avatar: PropTypes.string
}

export default UserDropdown
