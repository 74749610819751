/* eslint-disable react/prop-types */

import React, {useCallback, useMemo} from 'react'

import {ApartmentOutlined, DownOutlined} from '@ant-design/icons'
import {Dropdown, Empty, theme, Button} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import WorkspaceCard from 'containers/workspaces-manager/workspace-card.js'

import WorkspaceLeftButton from './workspace-left-button.js'

const DropdownButton = styled(Dropdown.Button)`
button {
  width: 100%;
}
  `

const DropdownMenuContainer = styled.div`
  max-height: 400px;
  min-width: 300px;
  overflow-y: auto;
  padding: ${({theme}) => theme.antd.paddingXXS}px;
`

function WorkspaceSelector({
  workspace,
  workspaces,
  handleSelectWorkspace
}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.WorkspaceManager'})

  const {token} = theme.useToken()

  const styles = {
    container: {
      width: '100%'
    },
    menu: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      backgroundColor: token.contentBgLight
    }
  }

  const startWorkspace = useCallback(async () => {
    try {
      await workspace.startWorkspace()
    } catch (error) {
      console.error('Failed to start workspace:', error)
    }
  }, [workspace])

  const items = useMemo(() => {
    const createWorkspaceItem = {
      label: (
        <Button
          type='primary'
          style={{width: '100%'}}
          icon={<ApartmentOutlined/>}
          onClick={startWorkspace}
        >
          {t('startWorkspace')}
        </Button>
      )
    }

    if (workspaces.length === 0) {
      return [
        createWorkspaceItem,
        {label: <Empty description={t('noWorkspace')}/>}
      ]
    }

    const items = workspaces.map(workspace => ({
      key: workspace._id,
      label: <WorkspaceCard {...workspace}/>
    }))
    return [createWorkspaceItem, ...items]
  }, [workspaces, startWorkspace, t])

  return (
    <DropdownButton
      icon={<DownOutlined/>}
      style={styles.container}
      menu={{
        items,
        style: styles.menu,
        onClick: handleSelectWorkspace
      }}
      trigger='click'
      buttonsRender={([leftButton, rightButton]) => [
        <WorkspaceLeftButton
          key='leftButton'
          leftButton={leftButton}
          workspace={workspace}
          startWorkspace={startWorkspace}
          activeWorkspaceCount={workspaces.filter(({status}) => status === 'active').length}
        />,
        rightButton
      ]}
      dropdownRender={menu => (
        <DropdownMenuContainer>
          {menu}
        </DropdownMenuContainer>
      )}
    />
  )
}

WorkspaceSelector.propTypes = {
  hasControls: PropTypes.bool
}

export default WorkspaceSelector
