import {EnvironmentOutlined, TeamOutlined} from '@ant-design/icons'
import {Flex, Divider} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import api from 'services/api/index.js'

import FullNameAvatar from 'components/display/avatar/full-name-avatar.js'
import OrganizationAvatar from 'components/display/avatar/organization-avatar.js'
import UsersAvatarGroup from 'components/display/avatar/users-avatar-group.js'
import ProfileInfo from 'components/user/profile-info.js'

function Summary({organization}) {
  const {t} = useTranslation('common')

  const {displayName, location, members} = organization
  const avatar = api.getAvatarURL(organization, 'large')

  return (
    <Flex vertical>

      <FullNameAvatar fullName={displayName} avatarPosition='top'>
        <OrganizationAvatar avatar={avatar}/>
      </FullNameAvatar>

      <Divider/>

      <Flex vertical gap='large'>

        <ProfileInfo
          icon={EnvironmentOutlined}
          title={t('common:bio.location')}
          value={location}
        />

        <ProfileInfo
          icon={TeamOutlined}
          title={t('common:bio.members')}
          value={(
            <UsersAvatarGroup profiles={members}/>
          )}
        />
      </Flex>
    </Flex>
  )
}

Summary.propTypes = {
  organization: PropTypes.object
}

export default Summary
