/* eslint-disable react/prop-types */

import {Modal} from 'antd'
import {useTranslation} from 'react-i18next'

function DeleteContainerModal({isOpen, onDelete, onCancel}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'FileList.DeleteContainerModal'

  return (
    <Modal
      open={isOpen}
      title={t(`${keyPrefix}.title`)}
      okText={t('common:button:remove')}
      cancelText={t('common:button:cancel')}
      okButtonProps={{danger: true}}
      onCancel={onCancel}
      onOk={onDelete}
    >
      {t(`${keyPrefix}.content`) /* TODO: wording */}
    </Modal>
  )
}

export default DeleteContainerModal
