import React, {useCallback, useMemo, useContext} from 'react'

import {EllipsisOutlined, CrownOutlined, DeleteOutlined, SwapOutlined, UserOutlined} from '@ant-design/icons'
import {Button, Dropdown, Flex, Typography, Tag} from 'antd'
import PropTypes from 'prop-types'
import {Link, useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import theme from 'theme.js'

import api from 'services/api/index.js'

import {getUserUrl} from 'helpers/url.js'

import {UserContext} from 'contexts/user.js'

import UserAvatar from 'components/display/avatar/user-avatar.js'

const {Text} = Typography

const {token} = theme

const StyledListItem = styled(Flex)`
  border-bottom: 1px solid ${({theme}) => theme.antd.colorBorder};
  padding: ${({theme}) => theme.antd.paddingSM}px;
  align-items: center;
`

const StyledTagText = styled.span`
  margin-left: ${({theme}) => theme.antd.margin}px;
`

function Member({
  member,
  setMembers,
  isUserMember = false,
  organization,
  setOrganization,
  message,
  t
}) {
  const {user} = useContext(UserContext)
  const navigate = useNavigate()
  const removeMember = useCallback(async memberId => {
    try {
      await api.removeUserFromOrganization(organization._id, memberId)
      setMembers(prev => (
        prev.filter(({_id}) => _id !== memberId)
      ))
      setOrganization(() => {
        const newMembers = organization.members.filter(({_id}) => _id !== memberId)
        return {
          ...organization,
          members: newMembers
        }
      })
      message.success(t('memberRemoved'))
      if (memberId === user._id) {
        navigate('/organizations')
      }
    } catch (error) {
      message.error(t(error.message))
      console.error(error)
    }
  }, [organization, message, t, setMembers, setOrganization, navigate, user._id])

  const items = useMemo(() => {
    if (isUserMember) {
      return [
        {
          key: 'manage',
          label: t('manageRole'),
          icon: <SwapOutlined/>,
          disabled: true
        },
        {
          key: 'remove',
          label: user._id === member._id ? t('quitOrganization') : t('removeMember'),
          icon: <DeleteOutlined/>,
          danger: true,
          onClick: () => removeMember(member._id)
        }
      ]
    }

    return []
  }, [t, isUserMember, member._id, removeMember, user._id])

  return (
    <StyledListItem justify='space-between'>
      <Flex align='center' gap='small'>
        <UserAvatar avatar={api.getAvatarURL(member)} size='large'/>
        <Link to={getUserUrl(member)}>
          <Text strong underline>
            {member.fullName}
          </Text>
        </Link>
      </Flex>

      <Flex align='center' gap='small'>
        <Tag color={member.role === 'admin' ? token.tagAdminColor : token.tagMemberColor}>
          {member.role === 'admin' ? (
            <CrownOutlined/>
          ) : (
            <UserOutlined/>
          )}
          <StyledTagText>{t(member.role)}</StyledTagText>
        </Tag>
        {isUserMember && (
          <Dropdown menu={{items}} trigger='click'>
            <Button type='text' icon={<EllipsisOutlined/>}/>
          </Dropdown>
        )}
      </Flex>
    </StyledListItem>
  )
}

Member.propTypes = {
  isUserMember: PropTypes.bool.isRequired,
  member: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
  }).isRequired,
  organization: PropTypes.object.isRequired,
  setOrganization: PropTypes.func.isRequired,
  setMembers: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired
}

export default React.memo(Member)
