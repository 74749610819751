/* eslint-disable react/prop-types, no-unused-vars */

import ParamsStep from 'components/display/operation-params/params-step.js'

import SelectTag from 'containers/form/select-tag.js'
import Select from 'containers/form/select.js'
import ParamsTimeline from 'containers/params-timeline.js'

export function Params({params, inputContext, preparationContext, triggerPreparation, t}) {
  const columnsOptions = inputContext?.schema?.map(({name}) => ({value: name, label: name}))
  return (
    <ParamsTimeline>
      <ParamsStep
        title={t('steps.columnsSelect')}
        checks={['params']}
      >
        <SelectTag
          label={t('params.columns')}
          name='columns'
          options={columnsOptions}
        />
      </ParamsStep>

      <ParamsStep
        title={t('steps.advancedParams')}
        checks={['citycode', 'postcode']}
      >
        <Select
          label={t('params.cityCode')}
          name='citycode'
          options={columnsOptions}
        />

        <Select
          label={t('params.postCode')}
          name='postcode'
          options={columnsOptions}
        />
      </ParamsStep>
    </ParamsTimeline>
  )
}
