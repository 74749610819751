export async function getHighlightedProjects() {
  return [
    {_id: '1', title: 'Officia voluptate dolore excepteur cupidatat velit', description: 'Description 1', owner: {_id: '1', displayName: 'ACME'}, _updated: '2021-01-01'},
    {_id: '2', title: 'Sint id excepteur nostrud incididunt consectetur commodo cillum qui.', description: 'Description 2', owner: {_id: '1', displayName: 'ACME'}, _updated: '2021-01-01'},
    {_id: '3', title: 'Consectetur nulla velit non tempor veniam.', description: 'Description 3', owner: {_id: '1', displayName: 'ACME'}, _updated: '2021-01-01'},
    {_id: '4', title: 'Consequat occaecat sint ullamco commodo', description: 'Description 4', owner: {_id: '1', displayName: 'ACME'}, _updated: '2021-01-01'}
  ]
}

export async function getNews() {
  return [
    {title: 'Anim elit sunt laborum culpa.', date: '2015-04-04', href: '/news/1'},
    {title: 'Aliquip consectetur nisi sit sit deserunt nisi adipisicing ea deserunt laborum exercitation cillum qui.', date: '2021-01-02', href: '/news/2'},
    {title: 'Adipisicing reprehenderit ex ipsum anim ad laborum et consequat.', date: '2024-01-10', href: '/news/3'}
  ]
}

export async function subscribeToNewsletter(email) {
  return email
}
