/* eslint-disable react/prop-types */

import Icon from '@ant-design/icons'
import styled from 'styled-components'

const NodeIcon = styled(Icon)`
    font-size: ${({theme}) => theme.antd.fontSizeHeading3}px;
    background-color: ${({theme, type}) => theme.antd[`${type}Color`]};
    border-radius: 100%;
    padding: ${({theme}) => theme.antd.paddingXS}px;
    color: ${({theme}) => theme.antd.colorWhite};
`

const NodeLogo = styled.img`
    width: 40px;
    height: 40px;
    object-fit: contain;
    background-color: ${({theme}) => theme.antd.contentBgLight};
    border-radius: ${({theme}) => theme.antd.borderRadius}px;
    padding: ${({theme}) => theme.antd.paddingXXS}px;
`

function OperationIcon({icon, type}) {
  return (
    typeof icon === 'string' ? (
      <NodeLogo src={icon} alt={type}/>
    ) : (
      <NodeIcon component={icon} type={type}/>
    )
  )
}

export default OperationIcon
