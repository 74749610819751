import React, {useMemo, useContext} from 'react'

import {ApiOutlined, CrownOutlined, ToolOutlined} from '@ant-design/icons'
import {Typography, theme as antdTheme} from 'antd'
import {useTranslation} from 'react-i18next'
import {Outlet, useNavigate} from 'react-router-dom'

import api from 'services/api/index.js'

import {getAccountSettingsUrl} from 'helpers/url.js'

import {UserContext} from 'contexts/user.js'

import useSettingsTab from 'hooks/use-settings-tab.js'

import UserAvatar from 'components/display/avatar/user-avatar.js'
import SettingsLayout from 'components/layouts/settings-layout.js'

import EditableAvatar from 'containers/user/editable-avatar.js'

function AccountSettingsLayout() {
  const {t} = useTranslation('translation', {keyPrefix: 'Settings'})
  const {token} = antdTheme.useToken()

  const {selectedTab} = useSettingsTab('general')

  const navigate = useNavigate()
  const {user, refreshUser} = useContext(UserContext)

  const menuProps = useMemo(() => {
    const items = [
      {
        type: 'group',
        label: t('tab.account'),
        children: [
          {
            key: 'general',
            label: t('tab.general'),
            icon: <ToolOutlined/>
          },
          {
            key: 'plan',
            label: t('tab.plan'),
            icon: <CrownOutlined/>
          }
        ]
      },
      {
        type: 'divider',
        style: {margin: token.marginMD}
      },
      {
        type: 'group',
        label: t('tab.access'),
        children: [
          {
            key: 'integrations',
            label: t('tab.integrations'),
            icon: <ApiOutlined/>
          }
        ]
      }
    ]

    return {
      items,
      onClick: ({key}) => navigate(getAccountSettingsUrl(key)),
      selectedKeys: [selectedTab === 'settings' ? 'general' : selectedTab]
    }
  }, [navigate, selectedTab, t, token.marginMD])

  const updateAvatar = async avatar => {
    try {
      if (avatar) {
        await api.updateAccountProfileAvatar(avatar)
      } else {
        await api.deleteAccountProfileAvatar()
      }

      refreshUser()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <SettingsLayout
      siderHeader={(
        <EditableAvatar
          fullName={user.fullName}
          updateAvatar={updateAvatar}
        >
          <UserAvatar avatar={api.getAvatarURL(user, 'large')} size={100}/>
        </EditableAvatar>
      )}
      menuProps={menuProps}
    >
      <Typography.Title level={2}>{t(`tab.${selectedTab}`)}</Typography.Title>
      <Outlet/>
    </SettingsLayout>
  )
}

export default React.memo(AccountSettingsLayout)
