import {useState} from 'react'

import PropTypes from 'prop-types'

import WorkflowSummary from 'containers/workflow-editor/workflow-summary.js'

function WorkflowSettings({workflow, isUserCanEdit}) {
  const [selectedSection, setSelectedSection] = useState(null)

  return (
    selectedSection || (
      <WorkflowSummary
        workflow={workflow}
        isUserCanEdit={isUserCanEdit}
        selectSection={setSelectedSection}
      />
    )
  )
}

WorkflowSettings.propTypes = {
  workflow: PropTypes.object.isRequired,
  isUserCanEdit: PropTypes.bool.isRequired
}

export default WorkflowSettings
