import {useContext, useEffect, useMemo, useState} from 'react'

import {LoginOutlined, PlusCircleOutlined} from '@ant-design/icons'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import api from 'services/api/index.js'

import {getNewProjectUrl, getSignInUrl, getSignUpUrl} from 'helpers/url.js'

import {UserContext} from 'contexts/user.js'

import useEventSource from 'hooks/use-event-source.js'

import WorkspacesActive from 'components/display/workspaces/workspaces-active.js'
import Header from 'components/layouts/header.js'

import LinkButton from 'containers/ui/button/link-button.js'
import UserDropdown from 'containers/ui/user-dropdown.js'
import WorkspacesDropdown from 'containers/workspaces-manager/workspaces-dropdown.js'

function HeaderContainer() {
  const {user, getAvatarURL} = useContext(UserContext)
  const [activeWorkspaces, setActiveWorkspaces] = useState([])

  const {t} = useTranslation('translation', {keyPrefix: 'Header'})

  const eventSource = useEventSource({url: '/events', enabled: Boolean(user)})

  // Initialize active workspaces list on component mount
  useEffect(() => {
    const fetchActiveWorkspace = async () => {
      if (await api.isAuthenticated()) {
        const workspaces = await api.getCurrentUserWorkspaces()
        const activeWorkspace = workspaces.filter(workspace => ['active', 'pending'].includes(workspace.status))
        setActiveWorkspaces(activeWorkspace)
      }
    }

    fetchActiveWorkspace()
  }, [])

  useEffect(() => {
    async function updateActiveWorkspace(message) {
      const {type, workspace, status} = message

      if (type === 'workspace:created') {
        setActiveWorkspaces(workspaces => [...workspaces, workspace])
      }

      if (type === 'workspace:status-updated') {
        if (status === 'active') {
          setActiveWorkspaces(workspaces => workspaces.map(ws => ws._id === workspace ? {...ws, status} : ws))
        } else {
          setActiveWorkspaces(workspaces => workspaces.filter(ws => ws._id !== workspace))
        }
      }
    }

    if (eventSource) {
      eventSource.on('message', updateActiveWorkspace)
    }

    return () => {
      if (eventSource) {
        eventSource.off('message', updateActiveWorkspace)
      }
    }
  }, [eventSource])

  const avatar = useMemo(() => {
    if (user && user.avatarKey) {
      return getAvatarURL()
    }
  }, [user, getAvatarURL])

  const menuItems = useMemo(() => {
    if (!user) {
      return [
        {label: (
          <LinkButton href={getSignUpUrl()} type='primary'>
            {t('signIn')}
          </LinkButton>
        )},
        {label: (
          <Link to={getSignInUrl()}>
            <LoginOutlined/><span>{t('login')}</span>
          </Link>
        )}
      ]
    }

    return [
      {label: (
        <LinkButton href={getNewProjectUrl()} icon={<PlusCircleOutlined/>} type='primary'>
          {t('newProject')}
        </LinkButton>
      )},
      activeWorkspaces.length > 0 && {label: (
        <WorkspacesDropdown workspaces={activeWorkspaces}>
          <WorkspacesActive activeWorkspacesCount={activeWorkspaces.length}/>
        </WorkspacesDropdown>
      )},
      {label: (
        <UserDropdown user={user} avatar={avatar}/>
      )}
    ]
  }, [user, avatar, activeWorkspaces, t])

  return (
    <Header menuItems={menuItems}/>
  )
}

export default HeaderContainer
