import React, {useContext} from 'react'

import {Button, Flex, Space, Tag, Tooltip, Typography, theme} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import api from 'services/api/index.js'

import {getTimeSince} from 'util/date.js'

import WorkflowContext from 'contexts/workflow-context.js'

import UserAvatar from 'components/display/avatar/user-avatar.js'
import UsersAvatarGroup from 'components/display/avatar/users-avatar-group.js'

const {Text} = Typography

const WorkspaceCardContainer = styled(Flex)`
  background-color: ${({theme}) => theme.antd.colorWhite};
  padding: ${({theme}) => theme.antd.paddingXS}px;
  border-radius: ${({theme}) => theme.antd.borderRadius}px;
`

function WorkspaceCard({status, createdBy, _id, activatedAt, stoppedAt, users}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'Project.WorkspaceCard'

  const {token} = theme.useToken()

  const {workspace, setCurrentWorkspaceId} = useContext(WorkflowContext)

  const avatar = api.getAvatarURL(createdBy, 'small')

  return (
    <WorkspaceCardContainer vertical gap='small'>
      <Flex justify='space-between' align='center'>
        <Tooltip title={createdBy.fullName}>
          <UserAvatar avatar={avatar}/>
        </Tooltip>
        <Tag color={token.workspaceStatusColor[status]}>{t(`common:workspace.status.${status}`)}</Tag>
      </Flex>

      <Text>
        {stoppedAt ? t(`${keyPrefix}.activeFor`) : t(`${keyPrefix}.activeSince`) }
        {t('common:date.since', {since: getTimeSince(activatedAt, stoppedAt, true)})}
      </Text>

      <Space>
        <Text>Utilisateurs : </Text>
        <UsersAvatarGroup profiles={users}/>
      </Space>
      <Button
        disabled={workspace._id === _id}
        onClick={() => setCurrentWorkspaceId(_id)}
      >
        {status === 'active' ? t(`${keyPrefix}.join`) : t(`${keyPrefix}.stoppedWorkspaceLabelButton`)}
      </Button>
    </WorkspaceCardContainer>
  )
}

WorkspaceCard.propTypes = {
  status: PropTypes.string.isRequired,
  createdBy: PropTypes.object.isRequired,
  activatedAt: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  stoppedAt: PropTypes.string,
  users: PropTypes.array.isRequired
}

export default React.memo(WorkspaceCard)
