import {HistoryOutlined, HourglassOutlined} from '@ant-design/icons'
import {Divider, Flex, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {styled} from 'styled-components'

import OrganizationAvatar from 'components/display/avatar/organization-avatar.js'
import UserAvatar from 'components/display/avatar/user-avatar.js'

import FullNameAvatar from '../avatar/full-name-avatar.js'

const {Text} = Typography

const LightText = styled(Text)`
  font-weight: ${({theme}) => theme.antd.fontWeightLight};
`

const ContainerSpace = styled(Flex)`
  padding: ${({theme}) => `${theme.antd.padding}px ${theme.antd.paddingXL}px`};
  font-weight: ${({theme}) => theme.antd.fontWeightLight};
  background-color: ${({theme}) => theme.antd.contentBgWhite};
  border-radius: ${({theme}) => theme.antd.borderRadius}px;
  width: 100%;

  .no-margin {
    margin: 0;
  }

  .strong-text {
    font-size: ${({theme}) => theme.antd.fontSizeSM}px;
  }
`
ContainerSpace.displayName = 'ProjectMeta'

function ProjectMeta({fullName, ownerType, avatar, creationDate, lastUpdate}) {
  const {t} = useTranslation(['translation', 'common'])

  const Avatar = ownerType === 'organization' ? OrganizationAvatar : UserAvatar

  return (
    <ContainerSpace
      vertical
      gap='middle'
    >
      <FullNameAvatar fullName={fullName} avatarPosition='top'>
        <Avatar avatar={avatar} size={ownerType === 'organization' ? 100 : 80}/>
      </FullNameAvatar>

      <Divider className='no-margin'/>

      <Text strong className='no-margin'>{t('ProjectMeta.title')}</Text>
      <Flex vertical>
        <Text strong className='strong-text'>
          <HourglassOutlined/> {t('common:date.createdSince')}<LightText>{t('common:date.since', {since: creationDate})}</LightText>
        </Text>
        <Text strong className='strong-text'>
          <HistoryOutlined/> {t('common:date.updatedSince')}<LightText>{t('common:date.since', {since: lastUpdate})}</LightText>
        </Text>
      </Flex>
    </ContainerSpace>
  )
}

ProjectMeta.propTypes = {
  fullName: PropTypes.string.isRequired,
  ownerType: PropTypes.oneOf(['organization', 'user']).isRequired,
  avatar: PropTypes.string,
  creationDate: PropTypes.string.isRequired,
  lastUpdate: PropTypes.string.isRequired
}

export default ProjectMeta
