import {useState} from 'react'

import {Flex, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import {buildTree} from 'util/file-tree.js'

import DropdownWrapper from 'components/display/dropdown-wrapper.js'
import FileExplorer from 'components/ui/file-explorer.js'
import Counters, {countFoldersAndFiles} from 'components/ui/fileset-explorer/counters.js'

const {Text} = Typography

function FileExplorerDropdown({files}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.OperationSider.OperationResult'})

  const [isOpen, setIsOpen] = useState(true)
  const counters = countFoldersAndFiles(buildTree(files))

  return (
    <DropdownWrapper
      title={(
        <Flex justify='space-between' align='center'>
          <Text>{t('filesListTitle')}</Text>
          <Counters {...counters} justify='end'/>
        </Flex>
      )}
      isOpen={isOpen}
      toggleOpen={() => setIsOpen(!isOpen)}
    >
      <FileExplorer files={files}/>
    </DropdownWrapper>
  )
}

FileExplorerDropdown.propTypes = {
  files: PropTypes.array
}

export default FileExplorerDropdown
