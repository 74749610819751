import {executeRequest} from './util/request.js'

export async function getProject({client, projectId}) {
  return executeRequest({url: '/projects/' + projectId, method: 'GET', client})
}

export async function getContainers({client, projectId}) {
  return executeRequest({url: `/projects/${projectId}/containers`, method: 'GET', client})
}

export async function createContainer({client, projectId, containerSlug}) {
  return executeRequest({url: `/projects/${projectId}/containers`, method: 'POST', body: {slug: containerSlug}, client})
}

export async function deleteContainer({client, projectId, containerSlug}) {
  return executeRequest({url: `/projects/${projectId}/containers/${containerSlug}`, method: 'DELETE', client})
}

export async function getWorkflow({client, workflowId}) {
  return executeRequest({url: '/workflows/' + workflowId, method: 'GET', client})
}

export async function getProjectWorkflows({client, projectId}) {
  return executeRequest({url: `/projects/${projectId}/workflows`, method: 'GET', client})
}

export async function createWorkflow({client, projectId}) {
  return executeRequest({
    url: `/projects/${projectId}/workflows`,
    method: 'POST',
    client
  })
}

export async function updateProject({client, projectId, changes}) {
  return executeRequest({url: '/projects/' + projectId, method: 'PUT', body: changes, client})
}

export async function deleteProject({client, projectId}) {
  return executeRequest({url: '/projects/' + projectId, method: 'DELETE', client})
}

export async function getAvailableOperations({client, projectId}) {
  return executeRequest({url: `/projects/${projectId}/available-operations`, method: 'GET', client})
}

export async function updateWorkflow({client, workflowId, changes}) {
  return executeRequest({url: '/workflows/' + workflowId, method: 'PUT', body: changes, client})
}

export async function deleteWorkflow({client, workflowId}) {
  return executeRequest({url: '/workflows/' + workflowId, method: 'DELETE', client})
}
