import {useEffect, useRef, useState} from 'react'

import {Spin, Upload, message} from 'antd'
import ImgCrop from 'antd-img-crop'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

const nbCount = 10
const maxSize = nbCount * 1024 * 1024 // 10 MB
const shapes = {
  round: {cropShape: 'round', listType: 'picture-circle'},
  square: {cropShape: 'square', listType: 'picture-card'}
}

const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

function AvatarUpload({shape = 'round', uploadAvatar, children}) {
  const {t} = useTranslation()

  const [messageApi, contextHolder] = message.useMessage()

  const [loading, setLoading] = useState(false)
  const uploadRef = useRef(null) // Utilisez useRef pour créer une référence stable

  // Trigger the file dialog when the component is mounted
  useEffect(() => {
    if (uploadRef.current) {
      const input = uploadRef.current.querySelector('input[type="file"]') // Cherchez l'élément input file
      if (input) {
        input.click()
      }
    }
  }, [])

  const handleChange = ({file}) => {
    if (file.status === 'uploading') {
      setLoading(true)
      return
    }

    if (file.status === 'done') {
      getBase64(file.originFileObj, () => {
        setLoading(false)
      })
    }
  }

  const beforeUpload = file => {
    const validTypes = new Set(['image/jpeg', 'image/png', 'image/webp'])

    if (!validTypes.has(file.type)) {
      message.error('AvatarUpload.Unsupported image format')
      return false
    }

    if (file.size > maxSize) {
      message.error(t('AvatarUpload.request entity too large'))
      return false
    }

    return true
  }

  const handleUpload = async ({file, onSuccess, onProgress, onError}) => {
    onProgress(0)

    try {
      const uploadResult = await uploadAvatar(file)
      onProgress(100)
      onSuccess(uploadResult)
      messageApi.success(t('AvatarUpload.success'))
    } catch (error) {
      onError(error)
      messageApi.error(t('AvatarUpload.fail', {error: t(`AvatarUpload.${error.message}`)}))
    }

    setLoading(false)
  }

  return (
    <div ref={uploadRef}>
      {contextHolder}

      <ImgCrop cropShape={shapes[shape].cropShape}>
        <Upload
          name='avatar'
          listType={shapes[shape].listType}
          accept='.jpeg,.jpg,.png,.webp'
          maxCount={1}
          showUploadList={false}
          beforeUpload={beforeUpload}
          customRequest={handleUpload}
          onChange={handleChange}
        >
          {loading ? (
            <Spin/>
          ) : (
            <div
              style={{
                borderRadius: shape === 'round' ? '50%' : 0,
                padding: '10px'
              }}
            >
              {children}
            </div>
          )}
        </Upload>
      </ImgCrop>
    </div>
  )
}

AvatarUpload.propTypes = {
  shape: PropTypes.oneOf(['round', 'square']),
  uploadAvatar: PropTypes.func.isRequired,
  children: PropTypes.node
}

export default AvatarUpload
