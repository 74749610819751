import React, {useEffect, useMemo, useCallback, useState} from 'react'

import PropTypes from 'prop-types'

import api from 'services/api/index.js'

import useAvailableOperations from 'hooks/use-available-operations.js'
import useWorkspace from 'hooks/use-workspace.js'

const WorkflowContext = React.createContext()

export function WorkflowProvider({initialValue, projectId, workspaceId, eventSource, children}) {
  const {_id: workflowId} = initialValue

  const [workflow, setWorkflow] = useState(initialValue)
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState(workspaceId)

  // Reset workflow state when initialValue changes
  useEffect(() => {
    setWorkflow(initialValue)
  }, [initialValue])

  useEffect(() => {
    setCurrentWorkspaceId(workspaceId)
  }, [workspaceId])

  const refreshWorkflow = useCallback(async () => {
    const workflow = await api.getWorkflow(workflowId)
    setWorkflow(workflow)
  }, [workflowId])

  const availableOperations = useAvailableOperations(projectId)

  const workspace = useWorkspace({workspaceId: currentWorkspaceId, workflowId, selectWorkspaceId: setCurrentWorkspaceId})

  useEffect(() => {
    async function updateWorflow(message) {
      const {type, workflow, changes} = message

      if (workflow === workflowId) {
        if (type === 'workflow:operations-updated') {
          await refreshWorkflow()
        } else if (type === 'workflow:updated') {
          setWorkflow(workflow => ({...workflow, ...changes}))
        }
      }
    }

    if (eventSource) {
      eventSource.on('message', updateWorflow)
    }

    return () => {
      if (eventSource) {
        eventSource.off('message', updateWorflow)
      }
    }
  }, [eventSource, workflowId, refreshWorkflow])

  const value = useMemo(() => ({
    workflow,
    workspace,
    currentWorkspaceId,
    setCurrentWorkspaceId,
    operations: workspace.operations || workflow.operations,
    availableOperations,
    setWorkflow
  }), [
    workflow,
    workspace,
    currentWorkspaceId,
    setCurrentWorkspaceId,
    availableOperations
  ])

  return (
    <WorkflowContext.Provider value={value}>
      {children}
    </WorkflowContext.Provider>
  )
}

WorkflowProvider.propTypes = {
  initialValue: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    operations: PropTypes.array.isRequired
  }).isRequired,
  projectId: PropTypes.string.isRequired,
  workspaceId: PropTypes.string,
  eventSource: PropTypes.object,
  children: PropTypes.node.isRequired
}

export default WorkflowContext
