import {Space, Typography} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const {Text} = Typography

const FullNameAvatarStyled = styled(Space)`
  &.left {
    flex-direction: row-reverse;
  }

  &.right {
    flex-direction: row;
  }

  &.top {
    flex-direction: column-reverse;

    .fullName {
      font-size: ${({theme}) => theme.antd.fontSizeLG}px;
    }
  }
`
FullNameAvatarStyled.displayName = 'FullNameAvatar'

function FullNameAvatar({fullName, avatarPosition = 'left', children}) {
  return (
    <FullNameAvatarStyled className={avatarPosition}>
      <Text className='fullName'>{fullName}</Text>
      {children}
    </FullNameAvatarStyled>
  )
}

FullNameAvatar.propTypes = {
  fullName: PropTypes.string.isRequired,
  avatarPosition: PropTypes.oneOf(['left', 'right', 'top']),
  children: PropTypes.node
}

export default FullNameAvatar

