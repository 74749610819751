import React from 'react'

import {Avatar, Tooltip} from 'antd'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

import api from 'services/api/index.js'

import {getOrganizationUrl} from 'helpers/url.js'

import OrganizationAvatar from 'components/display/avatar/organization-avatar.js'

function OrganizationAvatarGroup({profiles, avatarSize = 'small'}) {
  return (
    <Avatar.Group>
      {profiles.map(profile => {
        const {_id, fullName, displayName} = profile
        const title = fullName || displayName

        return (
          <Tooltip key={_id} title={title}>
            <Link to={getOrganizationUrl(profile)}>
              <OrganizationAvatar
                avatar={api.getAvatarURL(profile, avatarSize)}
                size='default'
              />
            </Link>
          </Tooltip>
        )
      })}
    </Avatar.Group>
  )
}

OrganizationAvatarGroup.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    fullName: PropTypes.string,
    displayName: PropTypes.string
  })).isRequired,
  avatarSize: PropTypes.string
}

export default React.memo(OrganizationAvatarGroup)
