/* eslint-disable react/prop-types */
import Icon from '@ant-design/icons'
import {Col, Flex, Row, Typography} from 'antd'
import styled from 'styled-components'

const {Title, Text} = Typography

const IllustratedSectionLayoutStyled = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  background: ${({background, theme}) => background === 'secondary' ? theme.antd.contentBgWhite : theme.antd.contentBgLight};
  border: ${({$isBordered, theme}) => $isBordered ? `1px solid ${theme.antd.colorBorder}` : 'none'};
  height: 100%;
  padding: ${({theme}) => `${theme.antd.paddingXL * 2}px`};

  @media (max-width: ${({theme}) => theme.antd.screenLGMax}px) {
    padding: ${({theme}) => `${theme.antd.paddingLG * 2}px`};
  }

  @media (max-width: ${({theme}) => theme.antd.screenXSMax}px) {
    padding: ${({theme}) => `${theme.antd.paddingMD}px`};
  }
`

const IllustrationCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 600px;
    width: 80%;
  }
`

const styles = {
  mainTitle: {
    textAlign: 'center',
    padding: '0 10%'
  },
  title: {
    margin: 0
  }
}

function IllustratedSectionLayout({
  mainTitle,
  title,
  description,
  icon,
  illustration,
  illustrationAlt,
  isReversed,
  children
}) {
  return (
    <IllustratedSectionLayoutStyled background={isReversed ? 'secondary' : 'primary'}>
      <Flex vertical gap='large'>
        {mainTitle && (
          <div style={styles.mainTitle}>
            <Title level={2}>{mainTitle}</Title>
          </div>
        )}

        <Row style={{flexDirection: isReversed ? 'row-reverse' : 'row'}} gutter={[32, 32]}>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Flex vertical gap='large' flex={1}>
              <Flex vertical>
                <Title level={5} style={styles.title}>
                  <Icon component={icon}/> {title}
                </Title>
                <Text type='secondary'>{description}</Text>
              </Flex>

              {children}
            </Flex>
          </Col>

          <IllustrationCol xs={24} sm={24} md={24} lg={12}>
            <img src={illustration} alt={illustrationAlt}/>
          </IllustrationCol>
        </Row>
      </Flex>
    </IllustratedSectionLayoutStyled>
  )
}

export default IllustratedSectionLayout
