/* eslint-disable react/prop-types */

import {StarOutlined} from '@ant-design/icons'
import {Col, Row} from 'antd'
import {useTranslation} from 'react-i18next'

import HighlightedProject from 'components/display/highlighted-project.js'
import IllustratedSectionLayout from 'components/layouts/illustrated-section-layout.js'

function HighlightedProjectsSection({highlightedProjects, ...props}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Home'})
  return (
    <IllustratedSectionLayout
      {...props}
      mainTitle={t('catchphrase')}
      title={t('HighlightedProjectsSection.title')}
      icon={StarOutlined}
      illustration='/images/data-workflow-illustration.svg'
      illustrationAlt={t('HighlightedProjectsSection.illustrationAlt')}
    >
      <Row gutter={[32, 32]}>
        {highlightedProjects.map(project => (
          <Col
            key={project._id}
            xs={24}
            sm={24}
            md={12}
            lg={12}
          >
            <HighlightedProject key={project._id} {...project}/>
          </Col>
        ))}
      </Row>
    </IllustratedSectionLayout>
  )
}

export default HighlightedProjectsSection
