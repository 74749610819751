import {useContext, useEffect, useState} from 'react'

import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import {OPERATIONS} from 'util/operations.js'

import {OperationParamsProvider} from 'contexts/operation-params-context.js'
import OperationsContext from 'contexts/operations-context.js'
import ProjectContext from 'contexts/project.js'

import useOperation from 'hooks/use-operation.js'

import OperationPanelHeader from 'components/display/operation-sider/operation-panel-header.js'
import FixedFrameLayout from 'components/layouts/fixed-frame-layout.js'

import OperationBadgeController from 'containers/workflow-editor/operation-side/operation-badge-controller.js'
import OperationPanelFooterController from 'containers/workflow-editor/operation-side/operation-panel-footer-controller.js'
import OperationTabs from 'containers/workflow-editor/operation-side/operation-tabs.js'

function getTab(operationStatus) {
  return ['executed', 'executing', 'failed'].includes(operationStatus) ? 'result' : 'params'
}

function OperationSider({workspaceId, isWorkspaceActive, operation}) {
  const {t} = useTranslation('operations', {keyPrefix: operation.type.replace(':', '-')})

  const {isUserCanEdit, availableOperations} = useContext(ProjectContext)
  const {handleSelectOperation} = useContext(OperationsContext)
  const {
    remove,
    progress,
    updateOperationError,
    preparation,
    execution,
    handleUpdate,
    prepare,
    execute,
    abort
  } = useOperation({workspaceId, operation})

  const [activeKey, setActiveKey] = useState(getTab(operation.status))
  const {icon, label, type, OperationParams, OperationExecution, defaultValues} = OPERATIONS[operation.type]
  const {inputType, outputType} = availableOperations.find(({type}) => type === operation.type)

  const isEditingEnable = isUserCanEdit && isWorkspaceActive && operation.isParamsEditable && !operation.isRequiredByExecution

  const handleClose = event => {
    event.stopPropagation()
    handleSelectOperation(null)
  }

  useEffect(() => {
    // The Parameters panel displays by default unless the operation is executed, failed, or in progress
    setActiveKey(getTab(operation.status))
  }, [operation.status])

  return (

    <FixedFrameLayout
      header={(
        <OperationPanelHeader
          title={t('label')}
          icon={icon}
          badge={<OperationBadgeController operation={operation}/>}
          type={type}
          io={{input: inputType, output: outputType}}
          onClose={handleClose}
        />
      )}
      footer={isUserCanEdit && isWorkspaceActive && (
        <OperationPanelFooterController
          operation={operation}
          hasPreparation={Boolean(preparation)}
          outputType={outputType}
          onUpdate={() => handleUpdate(defaultValues)}
          onPrepare={handleUpdate}
          onExecute={execute}
          onAbort={abort}
          onDelete={remove}
        />
      )}
    >
      <OperationParamsProvider
        key={operation._id} // Forces re-render when the operation changes to prevent params mix-up
        operationId={operation._id}
        initialParams={operation.params}
        isEditingEnable={isEditingEnable}
        defaultValues={defaultValues}
        handleUpdate={handleUpdate}
        validationErrors={updateOperationError}
      >
        <OperationTabs
          activeKey={activeKey}
          operationLabel={`${type}-${label}`}
          OperationParams={OperationParams}
          OperationExecution={OperationExecution}
          operation={operation}
          inputType={inputType}
          preparation={preparation}
          execution={execution}
          progress={progress}
          prepare={prepare}
          handleChange={setActiveKey}
        />
      </OperationParamsProvider>
    </FixedFrameLayout>
  )
}

OperationSider.propTypes = {
  workspaceId: PropTypes.string,
  isWorkspaceActive: PropTypes.bool.isRequired,
  operation: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.string,
    isParamsEditable: PropTypes.bool,
    isRequiredByExecution: PropTypes.bool,
    params: PropTypes.object
  }).isRequired
}

export default OperationSider
