import React from 'react'

import {Button, Typography, Flex, Divider} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SectionWrapper = styled(Flex)`
  background-color: ${({theme}) => theme.token.colorGray70};
  border-radius: ${({theme}) => theme.antd.borderRadius}px;
  padding: ${({theme}) => theme.antd.paddingSM}px;

  .divider {
    margin: 0;
  }

  .placeholder {
    max-width: 200px;
`

const Title = styled(Typography.Text)`
  margin: 0;
  font-size: ${({theme}) => theme.antd.fontSizeHeading5}px;
  font-weight: ${({theme}) => theme.antd.fontWeightStrong};
`

function SidebarSection({
  title,
  placeholderImage,
  placeholderImageAlt,
  actionButtonLabel,
  isPlaceholderDisplayed,
  handleAction,
  children
}) {
  return (
    <SectionWrapper vertical gap='middle'>
      {title && (
        <Flex vertical gap='small'>
          <Flex justify='space-between' align='baseline'>
            <Title>{title}</Title>
          </Flex>

          <Divider className='divider'/>
        </Flex>
      )}

      {isPlaceholderDisplayed && (
        <Flex justify='center'>
          <img className='placeholder' src={placeholderImage} alt={placeholderImageAlt}/>
        </Flex>
      )}

      {children}

      {handleAction && (
        <>
          {!isPlaceholderDisplayed && <Divider className='divider'/>}
          <Button
            type={isPlaceholderDisplayed ? 'primary' : 'text'}
            onClick={handleAction}
          >
            {actionButtonLabel}
          </Button>
        </>
      )}
    </SectionWrapper>
  )
}

SidebarSection.propTypes = {
  title: PropTypes.string,
  placeholderImage: PropTypes.string,
  placeholderImageAlt: PropTypes.string,
  actionButtonLabel: PropTypes.string,
  isPlaceholderDisplayed: PropTypes.bool,
  handleAction: PropTypes.func,
  children: PropTypes.node
}

export default SidebarSection
