import React from 'react'

import {EllipsisOutlined, MinusCircleOutlined, PlusCircleOutlined, TeamOutlined, UserOutlined} from '@ant-design/icons'
import {Avatar, Button, Dropdown, Flex, Typography} from 'antd'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import api from 'services/api/index.js'

import {getOrganizationUrl} from 'helpers/url.js'

const StyledListItem = styled(Flex)`
  border-bottom: 1px solid ${({theme}) => theme.antd.colorBorder};
  padding: ${({theme}) => theme.antd.paddingSM}px;
  align-items: center;
`
function Invitation({invitation, t, updateInvitations, updateOrganizations, user, message}) {
  const {organization} = invitation

  const acceptInvitation = async (invitationId, organization) => {
    try {
      await api.acceptInvitation(invitationId)
      updateInvitations(prev => prev.filter(({_id}) => _id !== invitationId))
      organization.members.push({
        _id: user._id,
        avatarKey: user.avatarKey,
        fullName: user.fullName,
        role: 'admin'
      })
      updateOrganizations(prev => [...prev, organization])
      message.success(t('invitationAccepted'))
    } catch (error) {
      message.error(t('invitationNotAccepted', {error}))
      console.error(error)
    }
  }

  const declineInvitation = async invitationId => {
    try {
      await api.declineInvitation(invitationId)
      updateInvitations(prev => prev.filter(({_id}) => _id !== invitationId))
      message.success(t('invitationDeclined'))
    } catch (error) {
      message.error(t('invitationNotDeclined', {error}))
      console.error(error)
    }
  }

  const items = [
    {
      key: 'accept',
      label: t('acceptInvitation'),
      icon: <PlusCircleOutlined/>,
      onClick: () => acceptInvitation(invitation._id, organization)
    },
    {
      key: 'decline',
      label: t('declineInvitation'),
      icon: <MinusCircleOutlined/>,
      danger: true,
      onClick: () => declineInvitation(invitation._id)
    }
  ]

  return (
    <StyledListItem justify='space-between'>
      {organization && (
        <>
          <Flex align='center' gap='small'>
            <Avatar src={api.getAvatarURL(organization)} icon={<TeamOutlined/>} size='large'/>
            <Link to={getOrganizationUrl(organization)}>
              <Typography.Text strong underline>
                {organization.displayName}
              </Typography.Text>
            </Link>
          </Flex>
          <Flex align='center' gap='small'>
            <Avatar.Group>
              {organization.members.map(member => (
                <Avatar key={member._id} src={api.getAvatarURL(member)} icon={<UserOutlined/>}/>
              ))}
            </Avatar.Group>
            <Dropdown menu={{items}} trigger='click'>
              <Button type='text' icon={<EllipsisOutlined/>}/>
            </Dropdown>
          </Flex>
        </>
      )}
    </StyledListItem>

  )
}

Invitation.propTypes = {
  invitation: PropTypes.object.isRequired,
  updateInvitations: PropTypes.func.isRequired,
  updateOrganizations: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

export default React.memo(Invitation)
