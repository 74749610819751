import {useContext, useState} from 'react'

import {UsergroupAddOutlined} from '@ant-design/icons'
import {Badge, message} from 'antd'
import {useTranslation} from 'react-i18next'
import {useLoaderData} from 'react-router-dom'

import api from 'services/api/index.js'

import {UserContext} from 'contexts/user.js'

import Section from 'components/layouts/section.js'

import Container from 'containers/layout/container.js'
import OrganizationsSection from 'containers/organizations/organizations-section.js'
import InvitationsList from 'containers/user/organizations/invitations-list.js'

export async function accountOrganizationLoader() {
  const [organizations, invitations] = await Promise.all([
    api.getAccountOrganizations(),
    api.getAccountInvitations()
  ])

  const organizationsPromises = organizations.map(async organization => {
    const organizationWithMembers = await api.getOrganization(organization._id)
    organization.members = organizationWithMembers.members
  })

  const invitationsPromises = invitations.map(async invitation => {
    const organization = await api.getOrganization(invitation.organization)
    invitation.organization = organization
  })

  await Promise.all([...organizationsPromises, ...invitationsPromises])

  return {organizations, invitations}
}

function AccountOrganizations() {
  const {t} = useTranslation('translation', {keyPrefix: 'Organization'})
  const [messageApi, contextHolder] = message.useMessage()
  const {user} = useContext(UserContext)

  const data = useLoaderData()

  const [organizations, setOrganizations] = useState(data.organizations)
  const [invitations, setInvitations] = useState(data.invitations)

  return (
    <Container>
      {contextHolder}
      <OrganizationsSection
        isUserAdmin
        isUserMember
        organizations={organizations}
      />
      {invitations && (
        <Section
          title={
            <>
              {t('pendingInvitations')}
              <Badge
                showZero
                color='black'
                count={invitations.length}
              />
            </>
          }
          icon={UsergroupAddOutlined}
          buttonLabel={t('inviteOrganizationButtonLabel')}
        >
          <InvitationsList
            invitations={invitations}
            updateInvitations={setInvitations}
            updateOrganizations={setOrganizations}
            user={user}
            message={messageApi}
          />
        </Section>
      )}
    </Container>
  )
}

export default AccountOrganizations
