/* eslint-disable react/prop-types */

import {NotificationOutlined} from '@ant-design/icons'
import {Timeline, Flex, Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {formatDate} from 'util/date.js'

import IllustratedSectionLayout from 'components/layouts/illustrated-section-layout.js'

const {Text} = Typography

const TimelineStyled = styled(Timeline)`
    a {
        color: ${({theme}) => theme.antd.colorText};
    }

    .ant-timeline-item-head-custom {
        background: transparent;
    }
`

const GradiantDot = styled.div`
    background: ${({theme}) => `linear-gradient(45deg, ${theme.antd.colorPrimaryBlue}, ${theme.antd.colorPrimaryGreen})`};
    border-radius: 50%;
    width: 14px;
    height: 14px;
`

function NewsSection({news, ...props}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Home.NewsSection'})
  return (
    <IllustratedSectionLayout
      {...props}
      title={t('title')}
      description={t('description')}
      icon={NotificationOutlined}
      illustration='/images/puzzle-illustration.svg'
      illustrationAlt={t('illustrationAlt')}
    >
      <TimelineStyled
        mode='left'
        items={news.map(news => (
          {
            dot: <GradiantDot/>,
            children: (
              <Flex vertical>
                <Text underline>
                  <a href={news.href}>{news.title}</a>
                </Text>
                <Text type='secondary'>{formatDate(news.date, 'long')}</Text>
              </Flex>
            )
          })
        )}
      />
    </IllustratedSectionLayout>
  )
}

export default NewsSection
