/* eslint-disable react/prop-types */

import React from 'react'

import {Flex} from 'antd'

function AlternateSections({children}) {
  return (
    <Flex vertical gap='large'>
      {React.Children.toArray(children).map((child, index) => (
        React.cloneElement(child, {
          isReversed: index % 2 === 1
        })
      ))}
    </Flex>
  )
}

export default AlternateSections
