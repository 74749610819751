
import {BellOutlined, MailOutlined} from '@ant-design/icons'
import {Form, Typography, Input, Button, Space, message} from 'antd'
import {useTranslation} from 'react-i18next'

import {subscribeToNewsletter} from 'services/api/platform.js'

import IllustratedSectionLayout from 'components/layouts/illustrated-section-layout.js'

const {Text} = Typography

function NewsletterSection({...props}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Home.NewsletterSection'})

  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()

  const onSubscribe = async values => {
    try {
      await subscribeToNewsletter(values.email)
      form.resetFields()
      messageApi.success(t('form.success'))
    } catch (error) {
      messageApi.error(t('form.fail', {error}))
    }
  }

  return (
    <IllustratedSectionLayout
      title={t('title')}
      description={t('description')}
      icon={BellOutlined}
      illustration='/images/newsletter-illustration.svg'
      illustrationAlt={t('illustrationAlt')}
      {...props}
    >
      {contextHolder}

      <Form
        form={form}
        layout='vertical'
        name='newsletter'
        validateTrigger='onSubmit'
        onFinish={onSubscribe}
      >
        <Form.Item
          name='email'
          label={<Text strong>{t('form.label')}</Text>}
          extra={t('form.help')}
          rules={[
            {type: 'email', message: t('form.invalidEmail')},
            {required: true, message: t('form.mandatoryField')}
          ]}
        >
          <Space align='center'>
            <Input
              prefix={<MailOutlined/>}
              type='email'
              autoComplete='email'
              placeholder={t('form.emailPlaceholder')}
            />
            <Button type='primary' htmlType='submit'>
              {t('form.submitButtonLabel')}
            </Button>
          </Space>
        </Form.Item>

      </Form>
    </IllustratedSectionLayout>
  )
}

export default NewsletterSection
