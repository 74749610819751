import React from 'react'

import {Avatar, Tooltip} from 'antd'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import api from 'services/api/index.js'

import {getUserUrl} from 'helpers/url.js'

import UserAvatar from 'components/display/avatar/user-avatar.js'

const AvatarStyled = styled(Avatar)`
  background-color: ${({theme}) => theme.antd.colorBGAvatar};
`
AvatarStyled.displayName = 'AvatarStyled'

function UsersAvatarGroup({profiles, avatarSize = 'small'}) {
  return (
    <Avatar.Group>
      {profiles.map(profile => {
        const {_id, fullName, displayName} = profile
        const title = fullName || displayName
        const avatar = api.getAvatarURL(profile, avatarSize)

        return (
          <Tooltip key={_id} title={title}>
            <Link to={getUserUrl(profile)}>
              <UserAvatar avatar={avatar} size='small'/>
            </Link>
          </Tooltip>
        )
      })}
    </Avatar.Group>
  )
}

UsersAvatarGroup.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    fullName: PropTypes.string,
    displayName: PropTypes.string
  })).isRequired,
  avatarSize: PropTypes.string
}

export default React.memo(UsersAvatarGroup)
