import {useCallback, useContext, useMemo} from 'react'

import {FastForwardOutlined, PlayCircleOutlined, PlusCircleOutlined, ReloadOutlined} from '@ant-design/icons'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import OperationsContext from 'contexts/operations-context.js'

import OperationPanelFooter from 'components/display/operation-sider/operation-panel-footer.js'

import {ReactComponent as BorderFilled} from 'assets/images/border-filled.svg'

function OperationPanelFooterController({operation, hasPreparation, outputType, onUpdate, onPrepare, onExecute, onAbort, onDelete}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'Project.Operations.Panel.Footer'

  const {handleSelectOperation, getNextOperations} = useContext(OperationsContext)

  const handleNextOperation = useCallback(() => {
    handleSelectOperation(operation._id)
  }, [operation._id, handleSelectOperation])

  const buttonProps = useMemo(() => {
    const {isParamsEditable, isRequiredByExecution, isExecutable, isOperationReady, isPreparable, isAbortable} = operation

    const defaultDisabledButton = {
      children: t(`${keyPrefix}.execute`),
      disabled: true
    }

    if (isAbortable) {
      return {
        danger: true,
        icon: <BorderFilled/>,
        onClick: onAbort,
        children: t(`${keyPrefix}.running`)
      }
    }

    if (!isParamsEditable || isRequiredByExecution) {
      return defaultDisabledButton
    }

    if (isPreparable || !hasPreparation) {
      return {
        icon: <ReloadOutlined/>,
        onClick: hasPreparation ? onPrepare : onUpdate,
        children: t(`${keyPrefix}.prepare`)
      }
    }

    if (isExecutable) {
      return {
        icon: <PlayCircleOutlined/>,
        onClick: onExecute,
        children: t(`${keyPrefix}.execute`)
      }
    }

    const nextOperations = getNextOperations(operation._id)
    if (operation.status === 'executed' || (outputType === 'stream' && isOperationReady)) {
      // Select next operation if there one
      if (nextOperations.length > 0) {
        return {
          icon: <FastForwardOutlined/>,
          onClick: () => handleSelectOperation(nextOperations[0]._id),
          children: t(`${keyPrefix}.next`)
        }
      }

      // Open modal to create next operation
      if (outputType) {
        return {
          icon: <PlusCircleOutlined/>,
          onClick: handleNextOperation,
          children: t(`${keyPrefix}.next`)
        }
      }
    }

    return defaultDisabledButton
  }, [operation, hasPreparation, outputType, onUpdate, onPrepare, onExecute, onAbort, t, keyPrefix, handleSelectOperation, handleNextOperation, getNextOperations])

  return (
    <OperationPanelFooter
      isDeleteDisabled={!operation.isDeletable}
      buttonProps={buttonProps}
      onDelete={onDelete}
    />
  )
}

OperationPanelFooterController.propTypes = {
  operation: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    isParamsEditable: PropTypes.bool.isRequired,
    isRequiredByExecution: PropTypes.bool.isRequired,
    isDeletable: PropTypes.bool.isRequired,
    isPreparable: PropTypes.bool.isRequired,
    isExecutable: PropTypes.bool.isRequired,
    isAbortable: PropTypes.bool.isRequired,
    isOperationReady: PropTypes.bool.isRequired,
    isCompleted: PropTypes.bool.isRequired,
    paramsStatus: PropTypes.string.isRequired,
    params: PropTypes.object
  }).isRequired,
  hasPreparation: PropTypes.bool.isRequired,
  outputType: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onPrepare: PropTypes.func.isRequired,
  onExecute: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default OperationPanelFooterController
