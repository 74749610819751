/* eslint-disable react/prop-types */
import {useContext} from 'react'

import {Flex, Typography, Select as SelectAntd, Tag} from 'antd'
import {useTranslation} from 'react-i18next'

import OperationParamsContext from 'contexts/operation-params-context.js'

import withEditable from 'hoc/with-editable.js'

import useField from 'hooks/use-field.js'

const {Text} = Typography

const styles = {
  tag: {
    margin: 0
  }
}

function Display({label, name}) {
  const {t} = useTranslation('common')
  const {params, initialParams} = useContext(OperationParamsContext)
  const tags = params[name] || initialParams[name]

  return (
    <Flex vertical>
      <Text>{label} :</Text>
      <Flex wrap='wrap' gap='small'>
        {tags ? tags.map(tag => (
          <Tag key={tag} style={styles.tag}>{tag}</Tag>
        )) : (
          <Text italic>{t('notSpecified')}</Text>
        )}
      </Flex>
    </Flex>
  )
}

function Field({name, defaultValue, options}) {
  const {params, initialParams, updateParam} = useContext(OperationParamsContext)

  const {value, initialValue, onChangeValue, handleSubmit} = useField({
    name,
    initialValue: params ? params[name] : initialParams[name],
    submit: updateParam
  })

  return (
    <SelectAntd
      mode='tags'
      value={value || initialValue}
      defaultValue={defaultValue}
      options={options}
      onBlur={handleSubmit}
      onChange={onChangeValue}
    />
  )
}

export default withEditable(Field, Display)
