import {useContext, useEffect, useCallback} from 'react'

import {Divider, Flex, Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import {Link, useLocation, useNavigate} from 'react-router-dom'

import api from 'services/api/index.js'

import {getSignUpUrl} from 'helpers/url.js'

import {UserContext} from 'contexts/user.js'

import useExecutor from 'hooks/use-executor.js'

import IllustratedLayout from 'components/layouts/illustrated-layout.js'
import Fail from 'components/sign-in/fail.js'
import Success from 'components/sign-in/success.js'
import ThirdPartiesLogin from 'components/sign-in/third-parties-login.js'

import Container from 'containers/layout/container.js'
import Meta from 'containers/layout/meta.js'
import SignInForm from 'containers/sign-in/sign-in-form.js'

const {Text} = Typography

const HOSTNAME_URL = process.env.REACT_APP_URL

function SignIn() {
  const askEmailAuthentication = useCallback(payload => api.askEmailAuthentication(payload), [])

  const {data, error, isPending, execute, cleanup} = useExecutor({
    handler: askEmailAuthentication
  })

  const {t} = useTranslation('translation', {keyPrefix: 'SignIn'})

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const {user} = useContext(UserContext)

  // Redirect user if connected
  useEffect(() => {
    if (user) {
      navigate(from, {replace: true})
    }
  }, [user, navigate, from])

  const onSubmit = async ({email}) => {
    execute({
      email,
      redirectUrl: `${HOSTNAME_URL}${from}`
    })
  }

  return (
    <Container>
      <Meta title={t('pageTitle')}/>

      <IllustratedLayout
        iconSrc='/living-data-logo.png'
        illustrationSrc='/images/signin-illustration.svg'
        illustrationAlt={t('illustrationAlt')}
        title={t('welcome')}
      >

        {error ? (
          <Fail error={error} t={t} onClick={cleanup}/>
        ) : (
          data ? (
            <Success t={t}/>
          ) : (
            <Flex vertical gap='large'>
              <div>
                <SignInForm isLoading={isPending} onSubmit={onSubmit}/>

                <Flex wrap='wrap' justify='center' gap='small'>
                  <Text>{t('createAccount')}</Text>

                  <Text>
                    <Link to={getSignUpUrl()}>{t('createAccountLink')}</Link>
                  </Text>
                </Flex>
              </div>

              <Divider plain>{t('alternativeSignIn')}</Divider>

              <ThirdPartiesLogin/>
            </Flex>
          ))}

      </IllustratedLayout>
    </Container>
  )
}

export default SignIn
